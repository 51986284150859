import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  GlobalStyles,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  backgroundColor,
  rightPanelColor,
  leftPnaleColor,
} from "../assets/colors";
import { LeftPanel } from "../components/left-panel";
import { RightPanel } from "../components/right-panel";
import Header from "../components/Header";
import SideMobileDrawer from "../components/responsive-left-panel";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { GLOBAL_URL } from "../utils/constants";
import axios from "axios";
import { useBookmark } from "../context/BookmarkContext";

import Sidebar from "../components/sidebar";
import { trackPageView } from "../utils/analytics";
import App from "../App";

// Step 1: Create a theme extending the default theme
const themes = createTheme({
  palette: {
    primary: {
      main: "#CB6CE6", // Your custom primary color
    },
  },
  typography: {
    fontFamily: `'Inter', sans-serif`, // Global font family
  },
});

function Chat(props: any) {
  const {
    isAuth,
    selectedProject,
    setSelectedProject,
    getHistory,
    searchHistory,
    setSearchHistory,
    darkMode,
    setDarkMode,
  } = props;

  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);
  const [openTemp, setOpenTemp] = React.useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [logoutNotification, setLogoutNotification] = useState<any>();
  const { setBookMark } = useBookmark();
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname);
  }, []);

  useEffect(() => {
    if (!isMobile) {
      setOpen(false);
    } else {
      setOpen(false);
    }
  }, [isMobile]);

  useEffect(() => {
    getBookmark();
  }, [selectedProject]);

  const togglePanel = () => {
    setOpen(!open);
  };

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpenTemp(newOpen);
  };

  const GlobalStyle = () => {
    return (
      <GlobalStyles
        styles={{
          /* For Webkit browsers (Chrome, Safari) */
          "*::-webkit-scrollbar": {
            width: "6px", // Thin scrollbar width
            height: "6px", // Thin scrollbar height for horizontal scroll
          },
          "*::-webkit-scrollbar-thumb": {
            // backgroundColor: "#CB6CE6", // Scrollbar thumb color (customized here)
            borderRadius: "10px", // Rounded corners for scrollbar
          },
          "*::-webkit-scrollbar-track": {
            // backgroundColor: "#f0f0f0", // Scrollbar track color
          },

          /* For Firefox (using scrollbar-width property) */
          "*": {
            scrollbarWidth: "thin", // Firefox equivalent of thin scrollbar
            // scrollbarColor: "#CB6CE6 #f0f0f0", // Thumb color and track color for Firefox
          },
        }}
      />
    );
  };

  const getNotification = async () => {
    const user_id = await localStorage.getItem("user_id");
    const params = {
      user_id: user_id,
    };
    try {
      const response = await fetch(`${GLOBAL_URL}/api/notification`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(params),
      });
      const responseData = await response.json();
      setNotificationData(responseData.notifications);
    } catch (error) {
      console.log(error, "====>");
    }
  };

  const getBookmark = async () => {
    const user_id = await localStorage.getItem("user_id");
    if (user_id) {
      const bookmarkData = {
        user_id: user_id,
        projectId: selectedProject,
      };

      try {
        const response = await axios.post(
          `${GLOBAL_URL}/api/bookmark/getBookmark`,
          bookmarkData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response && response.data) {
          const fetchedBookmarks = response.data;
          setBookMark(fetchedBookmarks);
        } else {
          console.error("Failed to retrieve bookmarks.");
        }
      } catch (error) {
        console.error("Error in getBookmark function:", error);
      }
    }
  };

  return (
    <App {...props} darkMode={darkMode} setDarkMode={setDarkMode} notificationData={notificationData}>
      <RightPanel
        toggleDrawer={toggleDrawer}
        opensidebar={open}
        getNotification={getNotification}
        isAuth={isAuth}
        selectedProject={selectedProject}
        setSelectedProject={setSelectedProject}
        isMobileResponsive={isMobile}
        logoutNotification={logoutNotification}
        setLogoutNotification={setLogoutNotification}
        setSearchHistory={setSearchHistory}
      />
    </App>
  );
}

export default Chat;
