import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  IconButton,
  Modal,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Properties } from "./properties";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import bookmarkAdd from "../assets/icons/bookmarkAdd.svg";
import bookmark from "../assets/icons/bookmark.svg";
import axios from "axios";
import { GLOBAL_URL } from "../utils/constants";
import { useGetBookmark } from "../hooks/getBookmarks";
import { BookmarkAdded } from "@mui/icons-material";
import { useBookmark } from "../context/BookmarkContext";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

export const SampleCard = ({
  result,
  handleClickOpen,
  resultPosition,
  setResultsFor,
  selectedProject,
  isBookmarked,
  isAuth,
}: any) => {
  const theme = useTheme();
  // const [isBookmarked, setIsBookmarked] = useState(true);
  const [openDetails, setOpenDetails] = useState(false);
  const [userEmail, setUserEmail] = useState();
  const [userId, setUserId] = useState();
  const { bookMark, setBookMark } = useBookmark();
  const navigate = useNavigate();

  const checkkk: any = bookMark.some(
    (ele: any) => ele.partent_id === result.id
  );

  useEffect(() => {
    const user: any = JSON.parse(localStorage.getItem("user") || "null");
    if (user) {
      setUserEmail(user.email);
      setUserId(user._id);
    }
  }, []);

  const handleBookmark = async (result: any) => {
    if (isAuth) {
      try {
        const url = `${GLOBAL_URL}/api/bookmark/createBookmark`;
        const bookMarkData: any = {
          projectId: selectedProject,
          user_id: userId,
          result: result,
          isBookmark: isBookmarked,
          patent_no: result?.id,
        };
        try {
          const response = await axios.post(url, bookMarkData, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (response.status === 200) {
            toast.success(response?.data?.message);
            getBookmark();
          } else {
            getBookmark();
          }
        } catch (err: any) {
          console.error("Error adding or removing bookmark:", err.message);
        } finally {
          getBookmark();
        }
      } catch (error) {
        console.log("error: ", error);
      }
    } else {
      navigate("/login");
    }
  };

  const getBookmark = async () => {
    const user_id = await localStorage.getItem("user_id");
    if (user_id) {
      const bookmarkData = {
        user_id: user_id,
        projectId: selectedProject,
      };

      try {
        const response = await axios.post(
          `${GLOBAL_URL}/api/bookmark/getBookmark`,
          bookmarkData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response && response.data) {
          const fetchedBookmarks = response.data;

          setBookMark(fetchedBookmarks);
        } else {
          console.error("Failed to retrieve bookmarks.");
        }
      } catch (error) {
        setBookMark([]);
        console.error("Error rrr in getBookmark function:", error);
      } finally {
      }
    }
  };

  return (
    <Card
      sx={{
        minWidth: "300px",
        maxWidth: "300px",
        background: theme.palette.searchInput?.background,
        padding: "10px",
        position: "relative",
        borderRadius: "5px",
      }}
      elevation={1}
    >
      <Stack direction={"column"} spacing="5px">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          position={"relative"}
        >
          {isAuth && (
            <IconButton
              onClick={() => handleBookmark(result)}
              color="default"
              style={{
                color: "black",
                padding: isBookmarked ? "5px" : "1px",
                borderRadius: "10px",
                paddingTop: isBookmarked ? "5px" : "3px",
                paddingBottom: isBookmarked ? "5px" : "3px",
                position: "absolute",
                // top:'18px',
                right: "12px",
                bottom: "10px",
                background: "lightgray",
                width: "30px",
                height: "30px",
              }}
            >
              {isBookmarked ? (
                <img src={bookmark} />
              ) : (
                <BookmarkAdded sx={{ color: "black" }} />
              )}
            </IconButton>
          )}
          <img
            src={result.image}
            alt=""
            width={"100%"}
            height={"250px"}
            style={{ borderRadius: "5px" }}
          />
        </Box>
        <Stack direction={"column"} spacing="10px" padding="20px">
          {result?.title && (
            <Properties
              keyString={"Title"}
              value={result.title.toUpperCase()}
            />
          )}
          {result?.publication_date && (
            <Properties
              keyString="Publication Date"
              value={result.publication_date}
            />
          )}
          {result?.id && (
            <Properties keyString="Patent Number" value={result.id} />
          )}

          <Button
            variant="outlined"
            onClick={() => {
              handleClickOpen();
              setResultsFor(resultPosition);
            }}
          >
            VIEW PATENT DETAILS
          </Button>
          <Button
            variant="outlined"
            onClick={() => window.open(result.www_link)}
          >
            SEE PATENT
          </Button>
        </Stack>
      </Stack>
      <Modal
        open={openDetails}
        onClose={() => setOpenDetails(false)}
        disablePortal={true}
      >
        <Stack
          direction={"column"}
          spacing="5px"
          borderRadius={"20px"}
          padding={"20px"}
          style={{
            maxWidth: "500px",
            top: "50%",
            left: "50%",
            position: "absolute",
            transform: "translate(-50%, -50%)",
            background: "white",
          }}
        >
          {Object.entries(result).map(([key, value], idx) => {
            if (key === "image_url" || key === "website") return <></>;
            else
              return (
                <Properties keyString={key} value={value as string} key={idx} />
              );
          })}
          <Button
            variant="outlined"
            onClick={() => window.open(result.website)}
          >
            Visit website
          </Button>
        </Stack>
      </Modal>
    </Card>
  );
};
