import React from "react";
import { Drawer, IconButton, Box, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import LanguageIcon from "@mui/icons-material/Language";
import WifiIcon from "@mui/icons-material/Wifi";
import PersonIcon from "@mui/icons-material/Person";
import StartIcon from "@mui/icons-material/Start";

import Whitelogo from "../assets/WhiteShortLogo.png";
import blacklogo from "../assets/blackShortLogo.png";
import newchatIcon from "../assets/icons/newChatGrayIcon.svg";
import plusIcon from "../assets/icons/plus-square.svg";
import BookmarkAddOutlinedIcon from "@mui/icons-material/BookmarkAddOutlined";
import whiteLogoRight from "../assets/whiteLogoRight.png";
import blackLogoRight from "../assets/blackLogoRight.png";

interface SidebarProps {
  sidebarOpen: any; // Define the 'sidebarOpen' prop type
  open: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ sidebarOpen, open }) => {
// console.log('open: mmmmm', open);
  const theme = useTheme();
  console.log("theme: ", theme.palette.mode);
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 80,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: 80,
          boxSizing: "border-box",
          backgroundColor: theme.palette.sidebar?.background,
          color: "#fff",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: 2,
          paddingBottom: 2,
          minWidth: "fit-content",
          //   borderColor:'#F3F2EC'
        },
      }}
    >
      <Box sx={{ position: "relative" }}>
        {!open && (
          <Box
            sx={{
              position: "fixed",
              top: 16,
              left: 80,
              padding: "10px",
            }}
          >
            <img
              src={
                theme.palette.mode === "dark" ? whiteLogoRight : blackLogoRight
              }
              style={{ cursor: "pointer", width: "160px" }}
              alt="img not found"
            />
          </Box>
        )}
      </Box>
      {/* Logo Section */}
      <Box
        sx={{
          marginBottom: 9,
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 2,
          //   paddingLeft: 2,
        }}
      >
        <img
          src={theme.palette.mode === "dark" ? Whitelogo : blacklogo}
          alt="Logo"
          style={{ width: 40 }}
        />
      </Box>

      {/* Icons Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1,
        }}
      >
        <IconButton
          onClick={sidebarOpen}
          sx={{
            color: "#fff",
            background: "#2D2F2F",
            width: 40,
            height: 40,
            marginBottom: 2,
            borderRadius: "50%",
            "&:hover": {
              color: "#2D2F2F",
            },
          }}
          aria-label="Add"
        >
          <img src={newchatIcon} />

          {/* <AddIcon /> */}
        </IconButton>
        <IconButton
          onClick={sidebarOpen}
          sx={{ color: "#2D2F2F" }}
          aria-label="Search"
        >
          {/* <SearchIcon /> */}
          <img src={plusIcon} />
        </IconButton>
        <IconButton
          onClick={sidebarOpen}
          sx={{ color: "#667085" }}
          aria-label="Language"
        >
          <BookmarkAddOutlinedIcon />
        </IconButton>
        <IconButton
          onClick={sidebarOpen}
          sx={{ color: "#667085" }}
          aria-label="Wifi"
        >
          <SearchIcon />
        </IconButton>
      </Box>

      {/* Logout Icon */}
      <Box sx={{ marginTop: "auto", marginBottom: 1 }}>
        <IconButton
          onClick={sidebarOpen}
          sx={{
            color: "#fff",
            background: "#2D2F2F",
            width: 40,
            height: 40,
            borderRadius: "50%",
          }}
          aria-label="Logout"
        >
          <StartIcon />
        </IconButton>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
