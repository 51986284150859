import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  createTheme,
  CssBaseline,
  GlobalStyles,
  Skeleton,
  Stack,
  ThemeProvider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import {
  backgroundColor,
  rightPanelColor,
  leftPnaleColor,
} from "../assets/colors";
import { LeftPanel } from "../components/left-panel";
import Header from "../components/Header";
import SideMobileDrawer from "../components/responsive-left-panel";
import { apiCall } from "../utils/httpClient";
import { CheckCircle } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GLOBAL_URL } from "../utils/constants";
import toast from "react-hot-toast";
import Sidebar from "../components/sidebar";
import App from "../App";

interface PlansScreenProps {
  setDialogOpen: (data: any) => void;
  dialogOpen: boolean;
  isAuth: boolean;
  id: any;
  open: any;
  isMobile: any;
}
const PlansScreen: React.FC<PlansScreenProps> = ({
  id,
  open,
  isMobile,
}: any) => {
  const theme = useTheme();
  const [plans, setplans] = useState<any>();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [userSubscription, setUserSubscription] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [planId, setplanId] = useState("");
  const [loadingBtn, setLoadingBtn] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      setplanId(id);
    }
  }, []);

  useEffect(() => {
    GetPlan();
  }, []);

  useEffect(() => {
    // Get the query parameters from the URL
    const urlParams = new URLSearchParams(window.location.search);

    // Extract the 'status' parameter
    const status = urlParams.get("status");
    const orderId = urlParams.get("token");

    if (status === "success" && orderId) {
      capturePayment(orderId);
    } else if (status === "failed") {
      toast.error("Payment Failed");
      navigate("/plans");
    }
  }, []);

  const createSubs = async (paymentIntent_id: any) => {
    const user_id = await localStorage.getItem("user_id");
    const urlParams = new URLSearchParams(window.location.search);
    const plan_id = urlParams.get("plan_id");
    const params = {
      plan_id: plan_id,
      user_id: user_id,
      orderID: paymentIntent_id,
    };
    try {
      const response = await fetch(
        `${GLOBAL_URL}/api/plan/createSubscription`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(params),
        }
      );
      const responseData = await response.json();

      toast.success(responseData?.message);
      navigate("/plans");
      await getUserSubscription();
    } catch (error) {
      console.log(error, "====>");
    }
  };

  const getUserSubscription = async () => {
    const user_id = await localStorage.getItem("user_id");
    const params = {
      user_id: user_id,
    };

    try {
      const response = await apiCall(
        "post",
        `${GLOBAL_URL}/api/plan/subscriptions`,
        params
      );
      if (response.status === 200) {
        await setUserSubscription(response.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        console.log("Error: Non-200 status code received");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const GetPlan = () => {
    apiCall("get", `${GLOBAL_URL}/api/plan/getPlans`)
      .then(function (response) {
        if (response.status == 200) {
          setplans(response.data);
          getUserSubscription();
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } else {
          console.log("error");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handlePayment = async () => {
    setLoadingBtn(true);
    const user_id = await localStorage.getItem("user_id");
    const params = {
      plan_id: "66fe627403566da9688246d0",
      user_id: user_id,
    };

    // Make the POST request using fetch
    const response = await fetch(`${GLOBAL_URL}/api/plan/createPayment`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    });

    // Check if the response is successful
    if (response.ok) {
      const data = await response.json(); // Parse the JSON response
      if (data?.approvalUrl) {
        window.location.href = data.approvalUrl;
      } else {
        console.error("Approval URL not found");
      }
    } else {
      console.error("Failed to create payment:", response.status);
    }
  };

  const capturePayment = async (orderId: any) => {
    setLoadingBtn(true);
    try {
      const response = await fetch(`${GLOBAL_URL}/api/plan/captureOrder`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ orderId }), // Pass the orderId to capture
      });

      if (response.ok) {
        createSubs(orderId);
      }
    } catch (error: any) {
      console.error("Error in capturing payment:", error.message);
    }
  };

  return (
    <div
      style={{
        paddingLeft: `${isMobile ? "0px" : open ? "50px" : "125px"} `,
        width: "100%",
        height:'100vh',
        paddingTop: isTablet ? '100px' : '0',
        display:"flex"
      }}
    >
      {plans &&
        plans.map((plan: any, index: any) =>
          userSubscription?.length > 0 &&
          userSubscription.some(
            (user_plan: any) => user_plan.plan_id === plan.plan_id
          ) ? (
            loading ? (
              <Skeleton
                variant="rounded"
                width={260}
                height={300}
                sx={{
                  marginTop: `${isMobile ? "0px" : "70px"}`,
                  marginLeft: "45px",
                }}
              />
            ) : (
              <Card
                key={index}
                sx={{
                  background: "linear-gradient(321deg, #E0DED6, #828282)",
                  color: "#000",
                  borderRadius: 2,
                  padding: "20px",
                  width: 265,
                  marginTop: "70px",
                  marginLeft: "45px",
                  // textAlign: 'center',
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: 24,
                      fontWeight: "bold",
                      marginBottom: 1, // 16px in MUI system
                    }}
                  >
                    {plan ? plan.plan_type : ""}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: 24,
                      fontWeight: "bold",
                      marginBottom: 1, // 16px in MUI system
                    }}
                  >
                    <CheckCircle color="primary" />
                  </Typography>
                </Stack>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h4"
                    fontWeight={400}
                    sx={{
                      // fontSize: 40,
                      marginBottom: 1, // 8px in MUI system
                    }}
                  >
                    ${plan.price}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 18,
                      // marginBottom: 1, // 8px in MUI system
                    }}
                  >
                    /{plan ? plan.plan_title : ""}
                  </Typography>
                </Stack>
                <Typography
                  variant="body2"
                  sx={{
                    marginBottom: 2, // 24px in MUI system
                  }}
                >
                  {plan.plan_description}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    marginBottom: 2, // 24px in MUI system
                  }}
                >
                  Expires on{" "}
                  {userSubscription
                    ? userSubscription[0].endDate.substr(0, 10)
                    : ""}
                </Typography>
                <Typography
                  sx={{
                    marginTop: 2,
                    textAlign: "center",
                    fontSize: "14px",
                    color: 'black',
                  }}
                >
                  FEATURES
                </Typography>
                <Typography
                  sx={{
                    fontSize: "13px",
                    padding: 0,
                    margin: 0,
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  {plan ? plan.features : ""}
                </Typography>
              </Card>
            )
          ) : loading ? (
            <Skeleton
              variant="rounded"
              width={260}
              height={300}
              sx={{ marginTop: "70px", marginLeft: "45px" }}
            />
          ) : (
            <Card
              key={index}
              sx={{
                background: "linear-gradient(321deg, #E0DED6, #828282)",
                color: "#000",
                borderRadius: 2,
                padding: "20px",
                width: 265,
                marginTop: `${isMobile ? "0px" : "70px"}`,
                marginLeft: "45px",
                // textAlign: 'center',
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: 24,
                  fontWeight: "bold",
                  marginBottom: 1, // 16px in MUI system
                }}
              >
                {plan ? plan.plan_type : ""}
              </Typography>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h4"
                  fontWeight={400}
                  sx={{
                    // fontSize: 40,
                    marginBottom: 1, // 8px in MUI system
                  }}
                >
                  ${plan ? plan.price : ""}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 18,
                    // marginBottom: 1, // 8px in MUI system
                  }}
                >
                  /{plan ? plan.plan_title : ""}
                </Typography>
              </Stack>
              <Typography
                variant="body2"
                sx={{
                  marginBottom: 2, // 24px in MUI system
                }}
              >
                {plan ? plan.plan_description : ""}
              </Typography>
              <Button
                // onClick={() => {
                //   openPaymentModal(plan.plan_id);
                //   setplanId(plan.plan_id);
                // }}
                onClick={handlePayment}
                variant="contained"
                color="primary"
                sx={{
                  color: theme.palette.button?.text,
                  background: theme.palette.sidebar?.background,
                  borderRadius: 3,
                  padding: 1.1, // 12px in MUI system
                  width: "100%",
                  marginTop: 1, // 16px in MUI system
                  fontWeight: 600,
                  "&:hover": {
                    backgroundColor: theme.palette.rightPanel?.background, // Darker shade for hover effect
                  },
                }}
              >
                {loadingBtn ? (
                  <CircularProgress color="inherit" size="25px" />
                ) : (
                  <>Pay</>
                )}
              </Button>
              <Typography
                sx={{
                  marginTop: 2,
                  textAlign: "center",
                  fontSize: "14px",
                  color: 'black',
                }}
              >
                FEATURES
              </Typography>
              <Typography
                sx={{
                  fontSize: "13px",
                  padding: 0,
                  margin: 0,
                  textAlign: "center",
                  marginTop: "15px",
                }}
              >
                {plan ? plan.features : ""}
              </Typography>
            </Card>
          )
        )}{" "}
    </div>
  );
};

function Plans(props: any) {
  const { getHistory, isAuth, searchHistory, setSearchHistory } = props;
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [dialogOpen, setDialogOpen] = useState(false); // To control modal open state
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (id) {
      setDialogOpen(true);
    }
  }, []);
  useEffect(() => {
    if (!isMobile) {
      setOpen(false);
    } else {
      setOpen(false);
    }
  }, [isMobile]);

  const [openTemp, setOpenTemp] = React.useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpenTemp(newOpen);
  };

  return (
    <App {...props}>
      <PlansScreen
        setDialogOpen={setDialogOpen}
        dialogOpen={dialogOpen}
        isAuth={isAuth}
        id={id}
        open={open}
        isMobile={isMobile}
      />
    </App>
  );
}

export default Plans;
