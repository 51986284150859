import ReactGA from 'react-ga4';


const TRACKING_ID = process.env.REACT_APP_MEASUREMENT_ID; 

ReactGA.initialize(TRACKING_ID, { debug: false });

// Function to track page views
export const trackPageView = (path) => {
  ReactGA.send({ hitType: "pageview", page: path });
};

// Function to track custom events
export const trackEvent = (category, action, label) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};
