import {
  IconButton,
  Paper,
  Typography,
  Stack,
  Box,
  Button,
  useMediaQuery,
  useTheme,
  Modal,
  Grid,
  Link,
  Avatar,
} from "@mui/material";
import { writeFileXLSX, utils } from "xlsx";
import { useEffect, useRef, useState } from "react";
import { useSendMessage } from "../hooks/sendMessage";
import { AccountCircle } from "@mui/icons-material";
import { SampleCard } from "./sample-cards";
import { rightPanelColor } from "../assets/colors";
import SearchInput from "./SearchInput";
import PatentDetailsModal from "./PatentDetailsModal";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import CategoryChart from "./CategoryChart";
import PatentChart from "./PatentChart";
import resultLogo from "../assets/resultlogo.png";
import Cookies from "js-cookie";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { GLOBAL_URL } from "../utils/constants";
import LoadingScreen from "./LoadingScreen";
import { useNavigate, useParams } from "react-router-dom";
import { useBookmark } from "../context/BookmarkContext";
import { useContextMessages } from "../context/MessageContext";
import { v4 as uuidv4 } from "uuid";
import { useSearchHistory } from "../context/HistoryContext";
import Whitelogo from "../assets/whiteLongLogo.png";
import blacklogo from "../assets/blackLongLogo.png";

export const RightPanel = ({
  isAuth,
  getNotification,
  selectedProject,
  setSelectedProject,
  opensidebar,
  isMobileResponsive,
  toggleDrawer,
  logoutNotification,
  setLogoutNotification,
  setSearchHistory,
}: any) => {
  const theme = useTheme();
  // console.log("theme: ", theme.breakpoints.keys);
  const { conversation_id } = useParams();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [detailsFor, setDetailsFor] = useState(0);
  const [results, setResults] = useState<any[]>([]);
  const [inputText, setInputText] = useState("");
  const [isGraphVisible, setIsGraphVisible] = useState(false);
  const patentChartRef = useRef<HTMLDivElement>(null);
  const categoryChartRef = useRef<HTMLDivElement>(null);
  const [credits, setCredits] = useState(0);
  const [warning, setWarning] = useState(false);
  const [loginWarn, setLoginWarn] = useState(false);
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [onlyCheck, setonlyCheck] = useState(true);
  const [graphLoading, setGraphLoading] = useState<boolean>(false);
  const userId = localStorage.getItem("user_id");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { bookMark } = useBookmark();
  const { setMessageContext } = useContextMessages();
  const bottomRef = useRef<HTMLDivElement | null>(null);

  const fullText = "AI Powered Patent Search"; // The full text to display
  const [displayedText, setDisplayedText] = useState(""); // Text to display
  const typingSpeed = 140; // Typing speed in ms

  const userDataString = localStorage.getItem("user");
  const userData = userDataString ? JSON.parse(userDataString) : null;
  const name = userData ? userData.first_name : null;

  const handleMessageReceived = async (decrement?: boolean) => {
    if (!decrement) {
      setBtnDisable(false);
    } else {
      if (isAuth) {
        await handleGetRealCredits();
        setBtnDisable(false);
        getNotification();
      } else {
        const decrementCredits = credits - 1;
        const updatedData = { aval_cred: decrementCredits };
        Cookies.set("aval_cred", JSON.stringify(updatedData), {
          expires: 365 * 20,
        });
        setBtnDisable(false);
      }
    }
  };
  const { sendMessage, loading, messages, setMessages } = useSendMessage(
    handleMessageReceived
  );

  useEffect(() => {
    let currentIndex = 0;

    // Create the interval for typing effect
    const interval = setInterval(() => {
      if (currentIndex < fullText.length) {
        setDisplayedText((prev) => fullText.slice(0, currentIndex + 1));
        currentIndex++;
      } else {
        currentIndex = 0; // Stop the interval once text is fully typed
        setDisplayedText("");
      }
    }, typingSpeed);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [fullText, typingSpeed]);

  useEffect(() => {
    setonlyCheck(true);

    if (isAuth) {
      setWarning(false);

      const checkCreditsAndPlan = async () => {
        try {
          const [creditsResult] = await Promise.all([handleGetCredits()]);

          if (creditsResult.hasPlan || creditsResult.hasCredits) {
            setWarning(false);
            setLoginWarn(false);
          } else {
            setLoginWarn(true);
          }
        } catch (error) {
          setWarning(false);
        }
      };

      checkCreditsAndPlan();
    } else {
      const check = Cookies.get("aval_cred");

      if (check) {
        const myData = Cookies.get("aval_cred");
        if (myData) {
          const parsedData = JSON.parse(myData);
          const NewCredits = parsedData.aval_cred;

          if (NewCredits == 0) {
            setWarning(true);
          }
          setCredits(NewCredits);
        }
      } else {
        const credits = { aval_cred: 20 };
        const jsonString = JSON.stringify(credits);
        document.cookie = `aval_cred=${jsonString}; path=/; max-age=${
          60 * 60 * 24 * 365 * 20
        }`;
        setCredits(20);
      }
    }
  }, []);

  useEffect(() => {
    if (isAuth) setWarning(false);
    setUuidInCookies();
  }, []);

  useEffect(() => {
    setWarning(false);
  }, [loginWarn]);

  useEffect(() => {
    if (isAuth) {
      if (conversation_id) {
        getConversations(conversation_id);
      } else {
        setMessages([]);
        setMessageContext([]);
      }
    }
  }, [conversation_id]);

  useEffect(() => {
    const message = messages[messages.length - 1];

    if (message?.results && message?.results.length) {
      setResults(message.results);
    }
    getHistory();
  }, [messages.length]);

  useEffect(() => {
    if (isAuth) {
      if (messages?.length > 0) storeConversation(messages);
    }
  }, [messages]);

  useEffect(() => {
    const chatEnd = document.getElementById("chart-end");
  }, [messages.length]);

  const setUuidInCookies = () => {
    let uuid = Cookies.get("user_uuid");
    if (!uuid) {
      uuid = uuidv4();
      Cookies.set("user_uuid", uuid, { expires: 7 }); // Set the UUID with a 7-day expiration
    }
    return uuid;
  };

  const getConversations = async (conversation_id: any) => {
    const url = `${GLOBAL_URL}/api/conversation/getConversation`;
    const data = {
      conversation_id: conversation_id,
      user_id: userId,
    };

    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        setMessages(response?.data?.data?.chats);
        setMessageContext(response?.data?.data?.chats);
        navigate(`/search/${conversation_id}`);
      } else {
        console.error("Failed to get projects:");
      }
    } catch (error) {
      console.error("Error in GetProject function:", error);
    }
  };

  const onSubmit = async () => {
    if (!btnDisable) {
      if (isAuth) {
        if (inputText) {
          const [creditsResult] = await Promise.all([handleGetCredits()]);

          if (creditsResult.hasPlan || creditsResult.hasCredits) {
            if (inputText.length > 0) {
              sendMessage(inputText, conversation_id);
              setBtnDisable(true);
            }
          } else {
            setLoginWarn(true);
          }
        }
      } else {
        if (credits > 0) {
          const decrementCredits = credits - 1;
          if (inputText.length > 0) {
            setBtnDisable(true);
          }
          if (inputText.trim().length > 0) {
            sendMessage(inputText, conversation_id);
            storeUnAuthConversation(inputText);
            setInputText("");
            setCredits(decrementCredits);
          }
          if (decrementCredits < 11) {
            setLogoutNotification(decrementCredits);
          }
        } else {
          setWarning(true);
        }
      }
    }
  };

  const storeUnAuthConversation = async (inputText: any) => {
    let params = {
      uuid: setUuidInCookies(),
      chats: inputText,
    };

    try {
      const response = await axios.post(
        `${GLOBAL_URL}/api/bookmark/unauthorizedConversation`,
        params
      );
      if (response.status === 200) {
        return { hasPlan: true, hasCredits: false };
      }
    } catch (error) {
      return { hasPlan: false, hasCredits: false };
    }
  };

  const handleGetRealCredits = async () => {
    const user_id = await localStorage.getItem("user_id");
    let params = {
      _id: user_id,
    };

    try {
      const response = await axios.post(
        `${GLOBAL_URL}/api/checkAvtCretits`,
        params
      );
      if (response.status === 200) {
        return { hasPlan: true, hasCredits: false };
      } else if (response.status === 201) {
        const data = response.data.avl_credits;
        return { hasPlan: false, hasCredits: true };
      } else {
        return { hasPlan: false, hasCredits: false };
      }
    } catch (error) {
      return { hasPlan: false, hasCredits: false };
    }
  };

  const handleGetCredits = async () => {
    const user_id = await localStorage.getItem("user_id");
    let params = {
      _id: user_id,
      onlyCheck: onlyCheck,
    };

    try {
      const response = await axios.post(
        `${GLOBAL_URL}/api/checkAvtCretits`,
        params
      );
      if (response.status === 200) {
        return { hasPlan: true, hasCredits: false };
      } else if (response.status === 202) {
        return { hasPlan: false, hasCredits: true };
      } else if (response.status === 400) {
        return { hasPlan: false, hasCredits: false };
      } else if (response.status === 201) {
        return { hasPlan: false, hasCredits: true };
      } else {
        return { hasPlan: false, hasCredits: false };
      }
    } catch (error) {
      return { hasPlan: false, hasCredits: false };
    }
  };

  const toggleViewGraph = () => {
    setIsGraphVisible(!isGraphVisible);
  };

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleExport = (RESULTS: any) => {
    // Prepare the data for the Excel sheet
    const worksheet = utils.json_to_sheet(RESULTS);

    // Create a new workbook
    const workbook = utils.book_new();

    // Add the worksheet to the workbook
    utils.book_append_sheet(workbook, worksheet, "Results");

    // Save the workbook as an Excel file
    writeFileXLSX(workbook, "results.xlsx");
  };

  const downloadChartAsPDF = async () => {
    setGraphLoading(true);
    const pdf = new jsPDF("p", "pt", "a4");

    if (patentChartRef.current && categoryChartRef.current) {
      // Capture the first chart
      const canvas1 = await html2canvas(patentChartRef.current);
      const imgData1 = canvas1.toDataURL("image/png");
      pdf.addImage(imgData1, "PNG", 20, 20, 550, 500); // pdf.addImage(imageData, format, x, y, width, height);
      // Capture the second chart
      const canvas2 = await html2canvas(categoryChartRef.current);
      const imgData2 = canvas2.toDataURL("image/png");
      pdf.addPage(); // Add a new page for the second chart
      pdf.addImage(imgData2, "PNG", 20, 20, 550, 500);
    }
    pdf.save("charts.pdf");
    setGraphLoading(false);
  };
  const highlight = [
    "US9904892B2",
    "This is a second key highlight for the patents.",
    "This is a third key highlight for the patents.",
  ];

  const handleDowloanPDF = async () => {
    const params = await {
      patents: results,
      highlight: highlight,
    };
    try {
      // Make a fetch request to the backend to get the PDF
      const response = await fetch(`${GLOBAL_URL}/api/pdf/generate-pdf`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Optional, mainly for server-side info
        },
        body: JSON.stringify(params),
      });

      if (!response.ok) {
        throw new Error("Failed to download PDF");
      }

      const blob = await response.blob();

      // Create a URL for the blob and set it as the href of a link element
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;

      // Set the filename for the downloaded PDF
      link.setAttribute("download", "download.pdf");

      // Append the link to the body, click it and then remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Optionally, revoke the object URL to free up memory
      window.URL.revokeObjectURL(url);
    } catch (error) {
      alert("There was an error downloading the PDF.");
    }
  };

  const storeConversation = async (messages: any) => {
    try {
      const data: object = {
        conversation_id: conversation_id,
        chats: messages,
        title: inputText,
        project_id: selectedProject,
        user_id: userId,
      };
      const response = await axios.post(
        `${GLOBAL_URL}/api/conversation/storeConversation`,
        data
      );
      if (response.status === 200) {
        setInputText("");
      } else {
        console.log("error in crateConversationId");
      }
    } catch (error) {
      return { hasPlan: false, hasCredits: false };
    }
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      if (bottomRef.current) {
        bottomRef.current.scrollTop = bottomRef.current.scrollHeight + 200;
      }
    }, 500);
  };

  const getHistory = async () => {
    const user_id = JSON.parse(localStorage.getItem("user") || "null")?._id;
    if (user_id) {
      const data = {
        project_id: selectedProject ?? "",
        user_id: user_id,
      };

      try {
        const response = await axios.post(
          `${GLOBAL_URL}/api/conversation/getSearchHistory`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response && response.data) {
          const fetchHistory = response.data.data;
          setSearchHistory(fetchHistory);
        } else {
          console.error("Failed to retrieve History.");
        }
      } catch (error) {
        console.error("Error rrr in History function:", error);
      }
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "90%",
        borderRadius: "20px",
        backgroundColor: theme.palette.rightPanel?.background,
        boxShadow: "none",
      }}
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Box
        sx={{
          textAlign: "center",
          fontSize: isMobile ? "20px" : "34px",
          fontWeight: "700",
          color: theme.palette.rightPanel?.text,
          display: isMobile ? "flex" : "",
          marginTop: "-20px",
          paddingBottom: "10px",
          paddingTop:
            messages?.length > 0 ? (!isTablet ? "10px" : "110px") : "110px",
        }}
      >
        {displayedText}
        {displayedText.length < fullText.length && "|"}
        {/* AI Powered Patent Search */}
      </Box>

      <Stack
        direction={"column"}
        width="100%"
        height={"100vh"}
        borderRadius="20px"
        style={{
          background: theme.palette.rightPanel?.background,
          overflow: "auto",
        }}
        spacing="20px"
        maxWidth={868}
      >
        <Stack
          direction={"column"}
          spacing="20px"
          id="chat-containor"
          style={{
            flex: 1,
            background: theme.palette.rightPanel?.background,
            overflowY: "auto",
            padding: !isTablet ? "12px 12px 280px 12px" : "0px 12px 280px",
          }}
          ref={bottomRef}
          sx={{
            "&::-webkit-scrollbar": {
              display: "none", // For Chrome, Safari, and Edge
            },
          }}
        >
          {messages.map((message, idx) => (
            <Stack
              direction="column"
              key={idx}
              spacing="10px"
              paddingLeft={1}
              paddingRight={1}
            >
              <Stack
                direction="row"
                spacing="10px"
                justifyContent={"start"}
                alignItems="end"
              >
                {message.isResponse ? (
                  <Stack
                    direction="row"
                    spacing={1.5}
                    justifyContent="start"
                    alignItems="flex-end"
                    sx={{ marginBottom: "15px" }}
                  >
                    <img
                      src={
                        theme.palette.mode === "dark" ? Whitelogo : blacklogo
                      }
                      alt="Logo"
                      style={{ height: 20, width: 80 }}
                    />
                  </Stack>
                ) : (
                  <Avatar
                    sx={{
                      width: 30,
                      height: 30,
                      backgroundColor: "black",
                      color: "white",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    {name ? name.charAt(0).toUpperCase() : ""}
                  </Avatar>
                )}
                <Typography
                  variant="body1"
                  fontWeight={"bold"}
                  sx={{ color: theme.palette.rightPanel?.text }}
                >
                  {message.isResponse ? "" : "You"}
                </Typography>
                <Typography variant="subtitle2">
                  {/* {message?.time?.toLocaleTimeString()} */}
                </Typography>
              </Stack>
              <Typography
                variant="body1"
                sx={{ color: theme.palette.rightPanel?.text }}
              >
                {message.message}
              </Typography>
              {results?.length > 0 && (
                <Box gap={2}>
                  {message.isResponse ? (
                    <>
                      {/* <Button
                        sx={{ alignSelf: "flex-start" }}
                        onClick={() => toggleViewGraph()}
                        className="my-link"
                      >
                        <Link underline="hover">View Graph</Link>
                      </Button> */}

                      <Box
                        sx={{
                          display: "flex", // Use flexbox for alignment
                          flexDirection: "row", // Stack buttons vertically
                          alignItems: "flex-end", // Align the content to the right
                          marginLeft: "auto", // Push the box to the right
                          gap: "8px", // Add space between buttons
                          width:"fit-content"
                        }}
                      >
                        {message.isResponse && (
                          <Button
                            sx={{ alignSelf: "flex-start" }} // Keep buttons aligned
                            onClick={() => handleExport(message.results)}
                          >
                            <Link underline="hover">Download Report</Link>
                          </Button>
                        )}
                        {message.isResponse && (
                          <Button
                            sx={{ alignSelf: "flex-start" }} // Keep buttons aligned
                            onClick={() => handleDowloanPDF()}
                          >
                            <Link underline="hover">Download pdf</Link>
                          </Button>
                        )}
                      </Box>

                      <Grid
                        display={"flex"}
                        justifyContent={"center"}
                        flexDirection={"column"}
                      >
                        <Grid style={{ display: "flex" }}>
                          <PatentChart
                            results={results}
                            patentChartRef={patentChartRef}
                            categoryChartRef={categoryChartRef}
                          />
                          <CategoryChart
                            results={results}
                            categoryChartRef={categoryChartRef}
                          />
                        </Grid>
                        <Button
                          variant="outlined"
                          onClick={downloadChartAsPDF}
                          sx={
                            {
                              // color: "rgb(98 203 255)",
                              // borderColor: "rgb(98 203 255)",
                            }
                          }
                        >
                          {graphLoading
                            ? "Preparing for Download...."
                            : "Download Graph"}
                        </Button>
                      </Grid>

                      {/* <Modal
                        open={isGraphVisible}
                        onClose={() => setIsGraphVisible(false)}
                        disablePortal={true}
                      >
                        <Stack
                          direction={"column"}
                          spacing="5px"
                          borderRadius={"20px"}
                          padding={"20px"}
                          style={{
                            top: "50%",
                            left: "50%",
                            position: "absolute",
                            transform: "translate(-50%, -50%)",
                            background: theme.palette.rightPanel?.background,
                          }}
                        >
                          <IconButton
                            onClick={() => setIsGraphVisible(false)}
                            style={{
                              zIndex: "99",
                              position: "absolute",
                              top: "10px",
                              right: "10px",
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                          <Grid
                            display={"flex"}
                            justifyContent={"center"}
                            flexDirection={"column"}
                          >
                            <Grid style={{ display: "flex" }}>
                              <PatentChart
                                results={results}
                                patentChartRef={patentChartRef}
                                categoryChartRef={categoryChartRef}
                              />
                              <CategoryChart
                                results={results}
                                categoryChartRef={categoryChartRef}
                              />
                            </Grid>
                            <Button
                              variant="outlined"
                              onClick={downloadChartAsPDF}
sx={{
                                // color: "rgb(98 203 255)",
                                // borderColor: "rgb(98 203 255)",
                              }}
                            >
                              {graphLoading
                                ? "Preparing for Download...."
                                : "Download Graph"}
                            </Button>
                          </Grid>
                        </Stack>
                      </Modal> */}
                    </>
                  ) : (
                    ""
                  )}
                </Box>
              )}
              {message?.results && message?.results.length > 0 && (
                <Stack
                  width={"100%"}
                  direction={"row"}
                  flexWrap={"wrap"}
                  gap={"50px"}
                  padding="20px"
                  paddingBottom="50px"
                  justifyContent={"center"}
                >
                  {message.results.map((result, idx) => {
                    const bookmarkedPatentNumbers = new Set(
                      bookMark.map((bookmark: any) => bookmark.patent_no)
                    );

                    const isBookmarked = bookmarkedPatentNumbers?.has(
                      result.publication_id
                    );
                    return (
                      <SampleCard
                        selectedProject={selectedProject}
                        handleClickOpen={handleClickOpen}
                        key={idx}
                        result={result}
                        setResultsFor={setDetailsFor}
                        resultPosition={idx}
                        isBookmarked={!isBookmarked}
                        isAuth={isAuth}
                      />
                    );
                  })}
                </Stack>
              )}
            </Stack>
          ))}
          <div id="chart-end"></div>
          {loading && (
            <>
              <Typography
                alignSelf={"flex-start"}
                sx={{ marginBottom: "70px" }}
              >
                <LoadingScreen />
              </Typography>
            </>
          )}
          <SearchInput
            inputText={inputText}
            setInputText={setInputText}
            onSubmit={() => {
              onSubmit();
            }}
            isMobile={isMobile}
            placeHolder={"Ask GetPatent!"}
            buttonLabel={"Send"}
            showAttachFileIcon={true}
            btnDisable={btnDisable}
            messages={messages}
            opensidebar={opensidebar}
            isMobileResponsive={isMobileResponsive}
            openmodalstate={open}
            isAuth={isAuth}
            scrollToBottom={scrollToBottom}
            conversation_id={conversation_id}
          />

          {warning ? (
            <p style={{ color: "red", textAlign: "center" }}>
              Your free credit quota has been expired. Please Register your
              account to get more credits.
            </p>
          ) : (
            ""
          )}

          {loginWarn ? (
            <p style={{ color: "red", textAlign: "center" }}>
              Your free credit quota has been expired. Please
              <Button
                onClick={() => navigate("/plans")}
                variant="text"
                sx={{
                  textTransform: "none",
                  fontSize: "1rem",
                  "&:hover": {
                    textDecoration: "underline", // Keep underline on hover
                  },
                }}
              >
                UPGRADE
              </Button>
            </p>
          ) : (
            ""
          )}
        </Stack>
        {/* Patent Details Modal */}
        <Modal open={open} onClose={handleClose}>
          <PatentDetailsModal
            results={results}
            detailsFor={detailsFor}
            handleClose={handleClose}
          />
        </Modal>
      </Stack>
    </Box>
  );
};
