import React, { useState, useRef, useEffect } from "react";
import { Box, Button, IconButton, ListItemText, Tooltip, Typography, useTheme } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { AddBox, DescriptionOutlined } from "@mui/icons-material";

interface CustomSelectProps {
  projects: any[]; 
  setEditEmail: (data: any) => void;
  setConfirmModalMsg: (data: any) => void;
  handleOpenModal: () => void;
  navigate: (path: string) => void;
  setSelectedProject: any;
  createProjectModelOpen: any;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  projects,
  setEditEmail,
  setConfirmModalMsg,
  handleOpenModal,
  navigate,
  setSelectedProject,
  createProjectModelOpen,
}) => {
  const [open, setOpen] = useState(false);
  const prevProject = localStorage.getItem("projectName")
  const [selectedOption, setSelectedOption] = useState<any>(
    prevProject
  );
  const [activeOption, setActiveOption] = useState<string | undefined>(
    undefined
  );
  const [contextMenuPosition, setContextMenuPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const contextMenuRef = useRef<HTMLDivElement>(null);
  const user: any = JSON.parse(localStorage.getItem("user") || "null");
  const theme = useTheme();


  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node) &&
      (!contextMenuRef.current || !contextMenuRef.current.contains(event.target as Node))
    ) {
      setOpen(false); 
      setContextMenuPosition(null); 
    }
  };

  useEffect(() => {
    // Clear localStorage on page refresh
    const handleRefresh = () => {
  localStorage.removeItem("projectName")
    };

    window.addEventListener("beforeunload", handleRefresh);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleRefresh);
    };
  }, []);
  

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

 
  const handleToggleDropdown = () => {
    setOpen((prev) => !prev);
  };

  // Handle selecting an option
  const handleOptionSelect = (option: any) => {
    console.log("option: ", option);
    setSelectedOption(option?.projectName ?? "Personal");
    setOpen(false); 
    setSelectedProject(option?.projectId ?? null);
    localStorage.setItem("projectId", option?.projectId ?? null);
    localStorage.setItem("projectName",option?.projectName ?? "Personal");
    navigate("/chat");
  };

  // Handle opening the custom context menu for an option
  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    option: string
  ) => {
    event.stopPropagation(); 
    setContextMenuPosition({ x: event.clientX, y: event.clientY - 100 });
    setActiveOption(option); 
  };

  // Handle "Edit" action
  const handleEdit = () => {
    if (activeOption) {
      setContextMenuPosition(null); 
      setEditEmail({ open: true, editData: activeOption });
    }
  };


  const handleDelete = () => {
    if (activeOption) {
      setContextMenuPosition(null); 
      setConfirmModalMsg({
        title: "Delete Project",
        caption: "Are you sure you want to delete this Project",
        activeOption,
      });
      handleOpenModal();
    }
  };

  console.log(selectedOption,"selectedOption")
  return (
    <div style={{ position: "relative" }}>
      <Box sx={{ display: "flex", alignItems: "center", padding:'0 10px' }}>
            <Button
              sx={{
                borderRadius: "50%",
                minWidth: "auto",
                boxShadow: "none",
                paddingLeft: "0px"
              }}
            >
                <DescriptionOutlined />
            </Button>
            <Typography
              color={theme.palette.sidebar?.text}
              fontSize={"18px"}
              fontWeight="600"
            >
              Projects
            </Typography>
          </Box>
      {/* Custom Select Input (styled div) */}
      <div
        onClick={handleToggleDropdown}
        style={{
          display: "flex",
        
          alignItems: "center",
          padding: "10px",
          cursor: "pointer",
          borderRadius: "4px",
          gap: "10px",
          width: "fit-content",
          marginLeft:'32px',
          marginTop:'-10px'
        }}
      >
        <span
          style={{
            fontSize: "15px",
            fontWeight: "600",
            color: theme.palette.sidebar?.text,
          }}
        >
          {selectedOption ? selectedOption : "Personal"}
        </span>
        <IconButton>
          <span
            style={{
              transform: open ? "rotate(180deg)" : "rotate(0deg)",
              display: "flex",
            }}
          >
            <ArrowDropDownIcon />
          </span>
        </IconButton>
      </div>

      {/* Dropdown Div */}
      {open && (
        <div
          ref={dropdownRef}
          style={{
            position: "absolute",
            backgroundColor: theme.palette.sidebar?.background,
            border: "1px solid #ccc",
            borderRadius: "4px",
            width:  "327px",
            marginTop: "5px",
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
            zIndex: 1,
            color: theme.palette.sidebar?.text,
            top: 50,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px",
              cursor: "pointer",
              background: theme.palette.rightPanel?.background,
              borderRadius: "4px",
            }}
            onClick={() => handleOptionSelect(null)}
          >
            <ListItemText
              primary={
                user ? (
                  "Personal"
                ) : (
                  <div>
                    <span
                      style={{
                        textDecoration: "underline",
                        fontStyle: "italic",
                        fontWeight: "700",
                      }}
                      onClick={() => navigate("/login")}
                    >
                      Login
                    </span>
                    &nbsp;to create project
                  </div>
                )
              }
            />
            <Tooltip title="Add New Project">
             
              <AddBox onClick={() => createProjectModelOpen(true)} />
            </Tooltip>
          </div>
          {projects?.length > 0 &&
            projects?.map((option, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0px 10px",
                  cursor: "pointer",
                }}
                onClick={() => handleOptionSelect(option)}
              >
                <ListItemText primary={option.projectName} />
                <IconButton onClick={(event) => handleMenuClick(event, option)}>
                  <MoreVertIcon />
                </IconButton>
              </div>
            ))}
        </div>
      )}

      {/* Custom Context Menu */}
      {contextMenuPosition && activeOption && (
        <div
          ref={contextMenuRef}
          style={{
            position: "absolute",
            left: contextMenuPosition.x,
            top: contextMenuPosition.y,
            backgroundColor: "white",
            border: "1px solid #ccc",
            borderRadius: "4px",
            width: "150px",
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
            zIndex: 2,
          }}
        >
          <div
            onClick={handleEdit}
            style={{
              padding: "10px",
              cursor: "pointer",
              borderBottom: "1px solid #ddd",
            }}
          >
            Edit
          </div>
          <div
            onClick={handleDelete}
            style={{
              padding: "10px",
              cursor: "pointer",
            }}
          >
            Delete
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
