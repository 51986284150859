// SearchInput.tsx
import React, { useEffect, useRef, useState } from "react";
import { Box, Button, IconButton, TextField, useTheme } from "@mui/material";
import pinImg from "../assets/icons/pin.svg";
import startImg from "../assets/icons/start.svg";
import { GLOBAL_URL } from "../utils/constants";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { PlayCircle } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import toast from "react-hot-toast";

interface SearchInputProps {
  inputText: string;
  setInputText: React.Dispatch<React.SetStateAction<string>>;
  onSubmit: () => void;
  isMobile: boolean;
  placeHolder: string;
  buttonLabel: string;
  showAttachFileIcon: boolean;
  btnDisable: boolean;
  messages?: any;
  opensidebar?: any;
  isMobileResponsive?: any;
  openmodalstate?: any;
  isAuth?: any;
  scrollToBottom?: any;
  conversation_id: any;
}

const SearchInput: React.FC<SearchInputProps> = ({
  inputText,
  setInputText,
  onSubmit,
  isMobile,
  placeHolder,
  buttonLabel,
  showAttachFileIcon,
  btnDisable,
  messages,
  opensidebar,
  isMobileResponsive,
  openmodalstate,
  isAuth,
  scrollToBottom,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [results, setResults] = useState<any>();
  const [text, setText] = useState("");
  const [message, setMessage] = useState("");
 


  const scrollRef = useRef<HTMLDivElement>(null);
  const scroll = (direction: "left" | "right") => {
    if (scrollRef.current) {
      const scrollAmount = 150; // Adjust scroll distance as needed
      scrollRef.current.scrollBy({
        left: direction === "right" ? scrollAmount : -scrollAmount,
        behavior: "smooth", // Smooth scrolling effect
      });
    }
  };

  const fetchResults = async () => {
    try {
      const response = await axios.get(
        `${GLOBAL_URL}/api/sugesstionTag/getTags`
      );
      console.log(response?.data?.data, "response");
      setResults(response?.data?.data);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  useEffect(() => {
    fetchResults();
  }, []);

  const { conversation_id } = useParams();
  const createConversationId = async () => {
    try {
      const response = await axios.get(
        `${GLOBAL_URL}/api/conversation/generateConversationId`
      );
      if (response.status === 200) {
        if (response?.data?.conversation_id) {
          navigate(`/search/${response?.data?.conversation_id}`);
        } else {
          alert("Error in creating conversation_id");
        }
      } else {
        console.log("error in generateConversationId");
      }
    } catch (error) {
      return { hasPlan: false, hasCredits: false };
    }
  };

  const handleSave = async () => {
    if (!text.trim()) {
      setMessage("Text is required");
      return;
    }
    const Data = {
      content: text,
    };
    try {
      const response = await axios.post(
        `${GLOBAL_URL}/api/sugesstionTag/saveTag`,
        Data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        toast.success(response?.data?.message);
        setMessage("Text saved successfully!");
        setText(""); // Clear input field after saving
        fetchResults();
      }
      if(response.status === 201){
        toast.error(response?.data?.message);
      }
    } catch (error: any) {
      setMessage(error?.response?.data?.message || "Something went wrong");
    }
  };

  const handleDeleteTask = async (taskId: string) => {
    const deleteData = {
      taskId: taskId,
    };
    try {
      const response = await axios.post(
        `${GLOBAL_URL}/api/sugesstionTag/deleteTag`,
        deleteData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        console.log(response, "response for delete");
        toast.success(response?.data?.message);
        fetchResults();
      }
    } catch (error: any) {
      setMessage(error?.response?.data?.message || "Something went wrong");
    }
  };

  const handleSubmit = () => {
    if (!conversation_id) {
      createConversationId();
    }
    scrollToBottom();
    onSubmit();
  };

  return (
    <Box
      className={`${messages?.length > 0 ? "search-input-bottom" : ""}`}
      sx={{ background: theme.palette.rightPanel?.background, zIndex: 1 }}
      
    >
      <Box
        sx={{
          backgroundColor: theme.palette.searchInput?.background,
          borderRadius: "25px",
          padding: "12px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          border: "1px solid #d6d5d5",
        }}
      >
        <textarea
          value={inputText}
          onChange={(e) => {
            const target = e.target;
            target.style.height = "auto"; 
            const maxHeight = 7 * 1.5 * 16; 
            if (target.scrollHeight > maxHeight) {
              target.style.height = `${maxHeight}px`; 
              target.style.overflowY = "auto"; 
            } else {
              target.style.height = `${target.scrollHeight}px`; 
              target.style.overflowY = "hidden"; 
            }
            setInputText(target.value);
          }}
          className="chat-input"
          placeholder={placeHolder}
          style={{
            padding: "8px",
            border: "1px solid transparent",
            outline: "none",
            width: "100%",
            resize: "none", 
            background: theme.palette.searchInput?.background,
            color: theme.palette.searchInput?.text,
            fontSize: "16px",
            lineHeight: "1.5", 
            borderRadius: "8px",

            maxHeight: `${7 * 1.5}em`, 
          }}
          rows={1} 
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleSubmit();
            }
          }}
        />

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            onClick={() => handleSubmit()}
            variant="contained"
            disabled={btnDisable}
            sx={{
              backgroundColor: theme.palette.sidebar?.background,
              borderRadius: "22px",
              "&:hover": {
                backgroundColor: theme.palette.sidebar?.background,
                color: theme.palette.sidebar?.text,
              },
              color: theme.palette.button?.text,
              fontWeight: "600",
              textTransform: "none",
              padding: "8px 16px",
              columnGap: "8px",
              whiteSpace: "nowrap", // Prevents wrapping
            }}
          >
            {!isMobile && buttonLabel} <PlayCircle />
          </Button>
        </Box>
      </Box>

      {/* add task */}
      {!conversation_id && ( // Check if conversation_id does not exist
        <Box>
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              marginTop: "25px",
              fontSize: "25px",
              fontWeight: "600",
              color: theme.palette.rightPanel?.text,
            }}
          >
            Filter
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "8px 12px",
              width: "100%",
              height: "80px",
              marginTop: "3px",
            }}
          >
            <TextField
              value={text}
              onChange={(e) => setText(e.target.value)}
              variant="outlined"
              placeholder="Enter Tag"
              sx={{
                marginRight: "8px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "20px",
                },
              }}
            />
            <Button
              onClick={handleSave}
              className="contained"
              variant="contained"
              disabled={btnDisable}
              sx={{
                backgroundColor: theme.palette.sidebar?.background,

                "&:hover": {
                  backgroundColor: theme.palette.sidebar?.background,
                  color: theme.palette.sidebar?.text,
                },
                color: theme.palette.button?.text,
                borderRadius: "20px",
                textTransform: "none",
                padding: "8px 16px",
                fontWeight: "600",
              }}
            >
              Add
            </Button>
          </Box>
        
         
            
            {results?.length > 0 && (
              <Box
                sx={{
                  position: "relative",
                  width: "80%",
                  display: "flex",
                  alignItems: "center",
                  margin: "auto",
                }}
              >
                {/* Previous Button */}
                <Button
                  onClick={() => scroll("left")}
                  sx={{
                    position: "absolute",
                    left: "-50px", 
                    top: "50%",
                    transform: "translateY(-50%)",
                    zIndex: 2,
                    backgroundColor:
                      theme.palette.mode === "light" ? "#E5E4DE" : "#303030", 
                    color: theme.palette.mode === "light" ? "#000" : "#fff", 
                    "&:hover": {
                      backgroundColor:
                        theme.palette.mode === "light" ? "#d2d1c9" : "#333", 
                    },
                    minWidth: "20px", 
                    height: "30px", 
                    borderRadius: "50%", 
                  }}
                >
                  <ArrowBackIcon />
                </Button>

                {/* Carousel Container */}
                <Box
                  ref={scrollRef}
                  sx={{
                    display: "flex",
                    gap: "8px",
                    justifyContent: "flex-start",
                    padding: "20px 0",
                    overflowX: "hidden", // Hide scrollbar
                    scrollBehavior: "smooth", // Smooth scrolling effect
                    whiteSpace: "nowrap",
                    flex: 1,
                  }}
                >
                  {results?.map((result: any, index: any) => (
                    <Button
                      variant="contained"
                      key={index}
                      sx={{
                        backgroundColor: theme.palette.button?.background,
                        borderRadius: "20px",
                        "&:hover": {
                          backgroundColor: theme.palette.sidebar?.background,
                          color: theme.palette.sidebar?.text,
                        },
                        color: theme.palette.button?.text,
                        height: "10%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "0 12px",
                        boxShadow: "none",
                        position: "relative",
                        flexShrink: 0,
                      }}
                    >
                      <Box
                        sx={{
                          flex: 1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {result.content}
                      </Box>
                      <IconButton
                        onClick={() => handleDeleteTask(result?._id)}
                        sx={{
                          marginLeft: "2px",
                          backgroundColor: "transparent",
                          color: theme.palette.button?.text,
                          padding: "4px 0px 4px 4px",
                          "&:hover": {
                            backgroundColor: theme.palette.sidebar?.background,
                            color: theme.palette.sidebar?.text,
                          },
                        }}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Button>
                  ))}
                </Box>

                {/* Next Button */}
                <Button
                  onClick={() => scroll("right")}
                  sx={{
                    position: "absolute",
                    right: "-50px", 
                    top: "50%",
                    transform: "translateY(-50%)",
                    zIndex: 2,
                    backgroundColor:
                      theme.palette.mode === "light" ? "#E5E4DE" : "#303030", 
                    color: theme.palette.mode === "light" ? "#000" : "#fff", 
                    "&:hover": {
                      backgroundColor:
                        theme.palette.mode === "light" ? "#d2d1c9" : "#333", 
                    },
                    minWidth: "20px", 
                    height: "30px",
                    borderRadius: "50%", 
                  }}
                >
                  <ArrowForwardIcon
                    sx={{
                      color:
                        theme.palette.mode === "light" ? "#171717" : "#fff",
                    }}
                  />{" "}
                  {/* Arrow color */}
                </Button>
              </Box>
            )}
         
        </Box>
      )}
    </Box>
  );
};

export default SearchInput;

