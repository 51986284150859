import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { customBlack, lightGray } from "../assets/colors";
import SearchIcon from "@mui/icons-material/Search";
import ProjectModal from "./emailAddModal";
import MoreVertSharpIcon from "@mui/icons-material/MoreVertSharp";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import { GLOBAL_URL } from "../utils/constants";
import { useBookmark } from "../context/BookmarkContext";
import { useNavigate } from "react-router-dom";
import PatentDetailsModal from "./PatentDetailsModal";
import toast from "react-hot-toast";
import ConfirmationModal from "./confirmationModal";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import CustomSelect from "./ProjectSelect";
import BookmarkAddOutlinedIcon from "@mui/icons-material/BookmarkAddOutlined";
import { EditNote, RadioButtonCheckedOutlined } from "@mui/icons-material";
import Whitelogo from "../assets/whiteLongLogo.png";
import blacklogo from "../assets/blackLongLogo.png";

interface LeftPanelProps {
  setOpenTemp: (open: boolean) => void; // Function to set the drawer open state
  selectedProject: any; // or define a more specific type if you know the structure
  setSelectedProject: (open: any) => void; // same as above, can be more specific
  sidebarClose: () => void;
  getHistory: any;
  searchHistory: any;
  setSearchHistory: any;
  getBookmark?: () => Promise<void>;
  GetProject?: () => Promise<void>;
  projects: any;
}

export const LeftPanel: React.FC<LeftPanelProps> = ({
  selectedProject,
  setSelectedProject,
  sidebarClose,
  getHistory,
  searchHistory,
  getBookmark,
  GetProject,
  projects,
}) => {
  // Tips panel state
  const theme = useTheme();

  const navigate = useNavigate();
  // const [projects, setProjects] = useState<any>();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // const [userEmail, setUserEmail] = useState();
  const [userId, setUserId] = useState();
  const [bookmarkId, setBookMarkId] = useState<any>();
  const { bookMark, setBookMark } = useBookmark();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [bookmarkanchorEl, setbookmarkAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const openOption = Boolean(anchorEl);
  const bookmarkopenOption = Boolean(bookmarkanchorEl);
  const [open, setOpen] = useState<boolean>(false);
  const [searchHistoryMenu, setsearchHistoryMenu] = React.useState<any>([]);
  const [editEmail, setEditEmail] = useState<any>({
    open: false,
    editData: {},
  });
  const [openPatentModal, setopenPatentModal] = useState(false);
  const [modalPatentDetail, setmodalPatentDetail] = React.useState<any>([]);
  const handleClosePatentModal = () => setopenPatentModal(false);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [confirmModalMsg, setConfirmModalMsg] = React.useState<any>({});
  const user: any = JSON.parse(localStorage.getItem("user") || "null");

  useEffect(() => {
    if (user) {
      // setUserEmail(user.email);
      setUserId(user._id);
      // GetProject();  uuu
    }
  }, []);

  const handleRadioChange = (projectId: any) => {
    setSelectedProject(projectId);
    localStorage.setItem("projectId", projectId);
    navigate("/chat");
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: any,
    index: any
  ) => {
    setbookmarkAnchorEl(event.currentTarget);
    setBookMarkId(item.bookmark_id);
  };

  const handleHistoryMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: any,
    index: any
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseOption = (bookmarkid: any) => {
    setbookmarkAnchorEl(null);
    setBookMarkId(bookmarkid);
  };

  const handleClose = async (e: any) => {
    setAnchorEl(null);
    setOpen(false);
    setEditEmail({
      open: false,
      editData: {},
    });
  };
  const handleBookmark = async (disc: any) => {
    try {
      const url = `${GLOBAL_URL}/api/bookmark/createBookmark`;
      const bookMarkData: any = {
        projectId: selectedProject ?? "",
        user_id: userId,
        result: disc?.result,
        isBookmark: false,
        patent_no: disc?.patent_no,
      };
      try {
        const response = await axios.post(url, bookMarkData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200) {
          toast.success(response?.data?.message);
          if (getBookmark) {
            getBookmark();
          }
        } else {
          if (getBookmark) {
            getBookmark();
          }
        }
      } catch (err: any) {
        console.error("Error adding or removing bookmark:", err.message);
      } finally {
        if (getBookmark) {
          getBookmark();
        }
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const handleDeleteHistory = async (disc: any) => {
    try {
      const url = `${GLOBAL_URL}/api/bookmark/deleteSearchHistory`;
      const data: any = {
        conversation_id: disc,
      };
      try {
        const response = await axios.post(url, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200) {
          toast.success(response?.data?.message);
          getHistory();
          setAnchorEl(null);
        } else {
          getHistory();
        }
      } catch (err: any) {
        console.error("Error adding or removing bookmark:", err.message);
      } finally {
        getHistory();
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  // const getBookmark = async () => {
  //   const user_id = await localStorage.getItem("user_id");
  //   if (user_id) {
  //     const bookmarkData = {
  //       user_id: user_id,
  //       projectId: selectedProject ?? "",
  //     };

  //     try {
  //       const response = await axios.post(
  //         `${GLOBAL_URL}/api/bookmark/getBookmark`,
  //         bookmarkData,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );
  //       if (response && response.data) {
  //         const fetchedBookmarks = response.data;
  //         // setBookmarks(fetchedBookmarks);
  //         setBookMark(fetchedBookmarks);
  //       } else {
  //         console.error("Failed to retrieve bookmarks.");
  //       }
  //     } catch (error) {
  //       setBookMark([]);
  //       console.error("Error rrr in getBookmark function:", error);
  //     }
  //   }
  // };

  const handleDelete = async (project: any) => {
    const data = {
      projectId: project.projectId,
    };

    try {
      const response = await axios.post(
        `${GLOBAL_URL}/api/project/deleteProject`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response) {
        toast.success(response?.data?.message);

        if (GetProject) {
          GetProject();
        }
        handleRadioChange(null);
      } else {
        console.error("Failed to retrieve bookmarks.");
      }
    } catch (error) {
      setBookMark([]);
      console.error("Error rrr in getBookmark function:", error);
    }
  };
  function capitalizeFirstLetter(str: any) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const handleConfirm = (value: any) => {
    if (value?.title === "Delete Project") {
      handleDelete(value?.activeOption);
    } else if (value?.title === "Delete History") {
      handleDeleteHistory(value?.history);
    } else if (value?.title === "Delete Bookmark") {
      handleBookmark(value?.desc);
    }
    setOpenConfirmModal(false);
  };

  const handleOpenModal = () => {
    setOpenConfirmModal(true);
  };

  const handleCloseModal = () => {
    setOpenConfirmModal(false);
  };

  return (
    <Stack
      direction="column"
      spacing="20px"
      paddingLeft={"20px"}
      paddingRight="20px"
     
     
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", }}>
        <img
          src={theme.palette.mode === "dark" ? Whitelogo : blacklogo}
          
          style={{ cursor: "pointer", width: "180px", }}
          onClick={() => navigate("/chat")}
          alt="img not found"
        />

        {!isMobile && (
          <IconButton
            sx={{
              width: 40,
              height: 40,
              borderRadius: "50%",
              color: theme.palette.sidebar?.text,
            }}
            onClick={sidebarClose}
          >
            <KeyboardTabIcon sx={{ transform: "rotate(-180deg)" }} />
          </IconButton>
        )}
      </Box>

      <div className="flex items-center justify-between">
        <CustomSelect
          projects={projects}
          setEditEmail={setEditEmail}
          setConfirmModalMsg={setConfirmModalMsg}
          handleOpenModal={handleOpenModal}
          navigate={navigate}
          setSelectedProject={setSelectedProject}
          createProjectModelOpen={setOpen}
        />
        <Tooltip title="New Chat">
          {/* <Button onClick={() => navigate('/chat')} sx={{marginRight: "10px", borderRadius:'50%', width:'22px', height:'32px'}}>  
            <EditNote
              style={{
                color: theme.palette.sidebar?.text,
                fontSize: "26px",
                
              }}
            />
          </Button> */}
          <IconButton
            sx={{
              width: 40,
              height: 40,
              borderRadius: "50%",
              color: theme.palette.sidebar?.text,
            }}
            onClick={() => navigate("/chat")}
          >
            <EditNote
              style={{
                fontSize: "26px",
              }}
            />
          </IconButton>
        </Tooltip>
      </div>
      <Stack
        style={{
          background: theme.palette.sidebar?.background,
          marginTop: 0,
          color: theme.palette.sidebar?.text,
        }}
        className="leftCart"
      >
        <Box sx={{ width: "100%" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              sx={{
                borderRadius: "50%",
                minWidth: "auto",
                boxShadow: "none",
                paddingLeft: "0px",
              }}
            >
              <BookmarkAddOutlinedIcon />
            </Button>
            <Typography
              color={customBlack}
              fontWeight="600"
              fontSize={"18px"}
              sx={{ color: theme.palette.sidebar?.text }}
            >
              Saved Patents
            </Typography>
          </Box>
          <Box
            sx={{
              maxHeight: "180px",
              overflowY: "auto",
              boxSizing: "border-box",
              // borderWidth: "1px",
              // borderColor: "#ccc",
              // scrollbarWidth: "thin",
              scrollbarWidth: "none", // Hide scrollbar by default for Firefox
              "&:hover": {
                scrollbarWidth: "thin", // Show scrollbar on hover for Firefox
                "&::-webkit-scrollbar": {
                  width: "5px", // Show thin scrollbar on hover for WebKit browsers
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#ccc", // Customize scrollbar thumb color on hover
                  borderRadius: "5px",
                },
              },
              "&::-webkit-scrollbar": {
                width: "0px", // Initially hide scrollbar for WebKit browsers
              },
            }}
          >
            {user ? (
              bookMark?.length > 0 ? (
                [...bookMark].reverse()?.map((desc: any, index: any) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "start",
                        position: "relative",
                        justifyContent: "space-between",
                        gap: "10px",
                        marginLeft: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                        }}
                      >
                        <RadioButtonCheckedOutlined
                          sx={{
                            fontSize: "12px",
                            marginTop: "14px",
                            marginRight: "8px",
                          }}
                        />

                        <Typography
                          onClick={() => {
                            setopenPatentModal(true);
                            setmodalPatentDetail(desc);
                          }}
                          key={index}
                          sx={{
                            fontSize: "14px",
                            color: theme.palette.sidebar?.text,
                            padding: "10px 0",
                            cursor: "pointer",
                            maxWidth: "200px",
                          }}
                        >
                          {desc?.result?.title &&
                            capitalizeFirstLetter(
                              desc.result.title.toLowerCase()
                            )}
                        </Typography>
                      </Box>

                      <Button
                        id={`basic-button ${desc?.patent_no}`}
                        aria-controls={
                          bookmarkopenOption ? "basic-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={bookmarkopenOption ? "true" : undefined}
                        onClick={(e) => handleClick(e, desc, index)}
                        sx={{
                          borderRadius: "50%",
                          width: "40px",
                          height: "40px",
                          minWidth: "auto",
                          boxShadow: "none",
                        }}
                      >
                        <MoreVertSharpIcon
                          sx={{ color: "#808080", fontSize: "18px" }}
                        />
                      </Button>
                      {bookmarkId === desc.bookmark_id && (
                        <Menu
                          id={`basic-button`}
                          anchorEl={bookmarkanchorEl}
                          open={bookmarkopenOption}
                          onClose={handleCloseOption}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                          PaperProps={{
                            sx: { boxShadow: "3px 4px 12px -2px lightgray" }, 
                          }}
                        >
                          <MenuItem
                            onClick={() => {
                              setConfirmModalMsg({
                                title: "Delete Bookmark",
                                caption:
                                  "Are you sure you want to delete this Bookmark",
                                desc,
                              });
                              handleOpenModal();
                            }}
                          >
                            Delete
                          </MenuItem>
                        </Menu>
                      )}
                    </Box>
                  );
                })
              ) : (
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: lightGray,
                    marginRight: "20px",
                    padding: "10px 0",
                    cursor: "pointer",
                  }}
                >
                  No Saved Patents Yet
                </Typography>
              )
            ) : (
              <Typography
                sx={{
                  fontSize: "14px",
                  color: theme.palette.sidebar?.text,
                  marginRight: "20px",
                  padding: "10px 0",
                  cursor: "pointer",
                }}
              >
                <span
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "700",
                  }}
                  onClick={() => navigate("/login")}
                >
                  Login
                </span>
                &nbsp;to save patents
              </Typography>
            )}
          </Box>
        </Box>
      </Stack>

      <Stack
        style={{
          background: theme.palette.sidebar?.background,
        }}
        className="leftCart"
      >
        <Box sx={{ width: "100%" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Button
              sx={{
                borderRadius: "50%",
                minWidth: "auto",
                boxShadow: "none",
                paddingLeft: "0px",
              }}
            >
              <SearchIcon />
            </Button>
            <Typography
              color={theme.palette.sidebar?.text}
              fontSize={"18px"}
              fontWeight="600"
              marginLeft={"-10px"}
            >
              Search History
            </Typography>
          </Box>

          <Box
            sx={{
              maxHeight: "150px",
              overflowY: "auto",
              boxSizing: "border-box",
              // scrollbarWidth: "thin",
              scrollbarWidth: "none", // Hide scrollbar by default for Firefox
              "&:hover": {
                scrollbarWidth: "thin", // Show scrollbar on hover for Firefox
                "&::-webkit-scrollbar": {
                  width: "5px", // Show thin scrollbar on hover for WebKit browsers
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#ccc", // Customize scrollbar thumb color on hover
                  borderRadius: "5px",
                },
              },
              "&::-webkit-scrollbar": {
                width: "0px", // Initially hide scrollbar for WebKit browsers
              },
            }}
          >
            {user ? (
              searchHistory?.length > 0 ? (
                [...searchHistory].reverse()?.map((desc: any, index: any) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginLeft: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <RadioButtonCheckedOutlined
                          sx={{
                            fontSize: "12px",
                            marginTop: "2px",
                            color: theme.palette.sidebar?.text,
                          }}
                        />
                        <Typography
                          onClick={() =>
                            navigate(`/search/${desc.conversation_id}`)
                          }
                          key={index}
                          sx={{
                            fontSize: "14px",
                            color: theme.palette.sidebar?.text,
                            marginRight: "20px",

                            padding: "10px 0",
                            cursor: "pointer",
                          }}
                        >
                          {desc?.title}
                        </Typography>
                      </Box>
                      <Button
                        id="basic-button"
                        aria-controls={openOption ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openOption ? "true" : undefined}
                        onClick={(e) => handleHistoryMenuClick(e, desc, index)}
                        sx={{
                          borderRadius: "50%",
                          width: "40px",
                          height: "40px",
                          minWidth: "auto",
                          boxShadow: "none",
                        }}
                      >
                        <div onClick={() => setsearchHistoryMenu(desc)}>
                          <MoreVertSharpIcon
                            sx={{ color: "#808080", fontSize: "18px" }}
                          />
                        </div>
                      </Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openOption}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        PaperProps={{
                          sx: { boxShadow: "3px 4px 12px -2px lightgray" },
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            setConfirmModalMsg({
                              title: "Delete History",
                              caption:
                                "Are you sure you want to delete this History",
                              history: searchHistoryMenu?.conversation_id,
                            });
                            handleOpenModal();
                          }}
                        >
                          Delete
                        </MenuItem>
                      </Menu>
                    </Box>
                  );
                })
              ) : (
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: lightGray,
                    marginRight: "20px",
                    padding: "10px 0",
                    cursor: "pointer",
                  }}
                >
                  No Search History Yet
                </Typography>
              )
            ) : (
              <Typography
                sx={{
                  fontSize: "14px",
                  color: theme.palette.sidebar?.text,
                  marginRight: "20px",
                  padding: "10px 0",
                  cursor: "pointer",
                }}
              >
                <span
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "700",
                  }}
                  onClick={() => navigate("/login")}
                >
                  Login
                </span>
                &nbsp;to save conversations
              </Typography>
            )}
          </Box>
        </Box>
      </Stack>
      <ProjectModal
        open={user ? open : false}
        handleClose={handleClose}
        GetProject={GetProject || (() => {})}
        editEmail={editEmail}
        setEditEmail={setEditEmail}
      />
      <Modal open={openPatentModal} onClose={handleClosePatentModal}>
        <PatentDetailsModal
          results={modalPatentDetail}
          handleClose={handleClosePatentModal}
        />
      </Modal>
      <ConfirmationModal
        open={openConfirmModal}
        confirmModalMsg={confirmModalMsg}
        onConfirm={handleConfirm}
        onClose={handleCloseModal}
      />
    </Stack>
  );
};
