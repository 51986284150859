import axios from "axios";
import { mockResults } from "./mock";
import React, { useEffect, useState } from "react";
import { v4 as Uuid } from "uuid";
import { GLOBAL_URL } from "../utils/constants";
import {
  NotificationManager,
  // @ts-ignore
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { escape } from "querystring";
import { useParams } from "react-router-dom";
import { useContextMessages } from "../context/MessageContext";

const greeting =
  "The CAR-T therapies targeting CD19 are used in cancer treatment with several patent applications. US20220047636A1 involves a method using chimeric antigen receptors (CARs) that bind to CD79b and CD19 to treat cancer patients. US20150283178A1 and US20210284752A1 both offer methods for treating diseases connected with CD19 expression using anti-CD19 CARs. Another patent, US20190117691A1, involves a PD-1 CAR-T cell for tumor treatment. US11154575B2 presents approaches that involve the use of anti-CD19 CAR expressing immune cells in immunotherapy. Lastly, US20180037625A1 describes the use of the CD2 signaling domain in second-generation CARs.";
const results = [
  {
    id: "US20220047636A1",
    type: "patent",
    publication_id: "US20220047636A1",
    title: "CHIMERIC ANTIGEN RECEPTORS TARGETING CD79B AND CD19",
    abstract:
      "Described herein are upfront methods for treating a patient suffering from a cancer. The method includes administering to the patient a therapeutically effective amount of an anti-cancer therapy including a chimeric antigen receptor (CAR) including an extracellular domain including a CD79b-binding domain and a CD19-binding domain, wherein the patient has not been previously treated for the cancer. The invention accordingly also relates to methods of producing and utilizing, e.g., T cells including CARs having an extracellular domain that binds CD79b and CD19.",
    publication_date: "2022-02-17",
    www_link: "https://patents.google.com/patent/US20220047636A1/en",
    owner: "The General Hospital Corporation",
    image: "https://api.projectpq.ai/patents/US20220047636A1/thumbnails/1",
    alias: "Maus",
    inventors: ["Marcela V. Maus"],
    score: 0.09328633546829224,
    snippet: null,
    mapping: null,
    index: "C07K",
  },
  {
    id: "US20150283178A1",
    type: "patent",
    publication_id: "US20150283178A1",
    title: "TREATMENT OF CANCER USING ANTI-CD19 CHIMERIC ANTIGEN RECEPTOR",
    abstract:
      "The invention provides compositions and methods for treating diseases associated with expression of CD19, e.g., by administering a recombinant T cell comprising the CD19 CAR as described herein, in combination with a kinase inhibitor, e.g., a kinase inhibitor described herein. The invention also provides kits and compositions described herein.",
    publication_date: "2015-10-08",
    www_link: "https://patents.google.com/patent/US20150283178A1/en",
    owner: "Assignee N/A",
    image: "https://api.projectpq.ai/patents/US20150283178A1/thumbnails/1",
    alias: "June et al.",
    inventors: [
      "Carl H. June",
      "David L. Porter",
      "Marcela Maus",
      "Mariusz Wasik",
      "Saar Gill",
      "Joseph Fraietta",
      "Marco Ruella",
      "John Byrd",
      "Jason Dubovsky",
      "Amy Johnson",
      "Natarajan Muthusamy",
      "Saad Kenderian",
      "Joan Mannick",
      "David Glass",
      "Leon Murphy",
      "Jennifer Brogdon",
      "William Raj Sellers",
    ],
    score: 0.09766119718551636,
    snippet: null,
    mapping: null,
    index: "A61K",
  },
  {
    id: "US20190117691A1",
    type: "patent",
    publication_id: "US20190117691A1",
    title:
      "PD-1 CAR-T CELL, PREPARATION METHOD THEREFOR, AND APPLICATION THEREOF",
    abstract:
      "Provided are a PD-1 CAR-T cell, a preparation method thereof, and an application thereof. By means of chimeric antigen receptor-modified T cell transformation, PD-1-CD8™-4-1BB-CD3ζ molecules are expressed in a T cell. The CAR-T cell prepared by the method can specifically recognize and bind with tumor cells with high PDL-1 protein expression, and is applicable to preparation of a drug for preventing and treating tumor diseases.",
    publication_date: "2019-04-25",
    www_link: "https://patents.google.com/patent/US20190117691A1/en",
    owner: "ASCLEPIUS (Suzhou) TECHNOLOGY COMPANY GROUP CO., LTD.",
    image: "https://api.projectpq.ai/patents/US20190117691A1/thumbnails/1",
    alias: "LI",
    inventors: ["HUASHUN LI"],
    score: 0.09858757257461548,
    snippet: null,
    mapping: null,
    index: "C12N",
  },
  {
    id: "US20210284752A1",
    type: "patent",
    publication_id: "US20210284752A1",
    title:
      "TREATMENT OF CANCER USING HUMANIZED ANTI-CD19 CHIMERIC ANTIGEN RECEPTOR",
    abstract:
      "The invention provides compositions and methods for treating diseases associated with expression of CD19. The invention also relates to chimeric antigen receptor (CAR) specific to CD19, vectors encoding the same, and recombinant T cells comprising the CD19 CAR. The invention also includes methods of administering a genetically modified T cell expressing a CAR that comprises a CD19 binding domain.",
    publication_date: "2021-09-16",
    www_link: "https://patents.google.com/patent/US20210284752A1/en",
    owner: "Novartis AG",
    image: "https://api.projectpq.ai/patents/US20210284752A1/thumbnails/1",
    alias: "Brogdon et al.",
    inventors: [
      "Jennifer Brogdon",
      "Carl H. June",
      "Andreas Loew",
      "Marcela Maus",
      "John Scholler",
    ],
    score: 0.09944474697113037,
    snippet: null,
    mapping: null,
    index: "C07K",
  },
  {
    id: "US20210128618A1",
    type: "patent",
    publication_id: "US20210128618A1",
    title: "BCMA-TARGETED CAR-T CELL THERAPY OF MULTIPLE MYELOMA",
    abstract:
      "Provided herein is a method of treating a subject who has a cancer. At least one dose of chimeric antigen receptor (CAR)-T cells comprising a CAR comprising a polypeptide is administered to the subject. The peptide comprises an extracellular antigen binding domain with at least two BCMA-binding moieties, a transmembrane domain, and an intracellular signaling domain. The dose of CAR-T cells administered to the subject is from 4.0×105 to 1.0×106 of CAR-T cells per kilogram of the subject's mass. Alternatively, the dose comprises 1×106 to 1×108 of the CAR-T cells.",
    publication_date: "2021-05-06",
    www_link: "https://patents.google.com/patent/US20210128618A1/en",
    owner: "Janssen Biotech, Inc.",
    image: "https://api.projectpq.ai/patents/US20210128618A1/thumbnails/1",
    alias: "UBANI",
    inventors: ["Enrique ZUDAIRE UBANI"],
    score: 0.10206788778305054,
    snippet: null,
    mapping: null,
    index: "A61K",
  },
  {
    id: "US20150118202A1",
    type: "patent",
    publication_id: "US20150118202A1",
    title: "Methods for Treatment of Cancer",
    abstract:
      "The present invention provides compositions and methods for treating cancer in a human. The invention includes relates to administering a genetically modified T cell to express a CAR wherein the CAR comprises an antigen binding domain, a transmembrane domain, a costimulatory signaling region, and a CD3 zeta signaling domain",
    publication_date: "2015-04-30",
    www_link: "https://patents.google.com/patent/US20150118202A1/en",
    owner: "Assignee N/A",
    image: "https://api.projectpq.ai/patents/US20150118202A1/thumbnails/1",
    alias: "June et al.",
    inventors: [
      "Carl H. June",
      "Bruce L. Levine",
      "David L. Porter",
      "Michael D. Kalos",
      "Michael C. Milone",
    ],
    score: 0.10222834348678589,
    snippet: null,
    mapping: null,
    index: "C07K",
  },
  {
    id: "US20180258391A1",
    type: "patent",
    publication_id: "US20180258391A1",
    title: "Compositions and Methods for Treatment of Cancer",
    abstract:
      "The present invention provides compositions and methods for treating cancer in a human. The invention includes relates to administering a genetically modified T cell to express a CAR wherein the CAR comprises an antigen binding domain, a transmembrane domain, a costimulatory signaling region, and a CD3 zeta signaling domain.",
    publication_date: "2018-09-13",
    www_link: "https://patents.google.com/patent/US20180258391A1/en",
    owner: "Assignee N/A",
    image: "https://api.projectpq.ai/patents/US20180258391A1/thumbnails/1",
    alias: "June et al.",
    inventors: [
      "Carl H. June",
      "Bruce L. Levine",
      "David L. Porter",
      "Michael D. Kalos",
      "Michael C. Milone",
    ],
    score: 0.10275077819824219,
    snippet: null,
    mapping: null,
    index: "C07K",
  },
  {
    id: "US11154575B2",
    type: "patent",
    publication_id: "US11154575B2",
    title:
      "Cancer immunotherapy using CD19-directed chimeric antigen receptors",
    abstract:
      "Several embodiments provided for relate to methods of using of anti-CD19 CAR expressing immune cells in immunotherapy. Provided for herein in several embodiments are immune cell-based (e.g., natural killer (NK) cell) compositions comprising CD19-directed chimeric antigen receptors. In some, embodiments the anti-CD19 binder portion of the CAR is humanized. In several embodiments, the humanized anti-CD19 CAR expressing cells exhibit enhanced expression of the CAR as well as enhanced cytotoxicity and/or persistence.",
    publication_date: "2021-10-26",
    www_link: "https://patents.google.com/patent/US11154575B2/en",
    owner: "Nkarta, Inc.",
    image: "https://api.projectpq.ai/patents/US11154575B2/thumbnails/1",
    alias: "Trager et al.",
    inventors: [
      "James Barnaby Trager",
      "Luxuan Guo Buren",
      "Chao Guo",
      "Mira Tohmé",
      "Ivan Chan",
      "Alexandra Leida Liana Lazetic",
    ],
    score: 0.1040908694267273,
    snippet: null,
    mapping: null,
    index: "C07K",
  },
  {
    id: "US20180037625A1",
    type: "patent",
    publication_id: "US20180037625A1",
    title:
      "USE OF THE CD2 SIGNALING DOMAIN IN SECOND-GENERATION CHIMERIC ANTIGEN RECEPTORS",
    abstract:
      "The present invention provides compositions and methods for treating cancer in a human. The invention includes relates to administering a genetically modified T cell expressing a CAR having an antigen binding domain, a transmembrane domain, a CD2 signaling domain, and a CD3 zeta signaling domain. The invention also includes incorporating CD2 into the CAR to alter the cytokine production of CAR-T cells in both negative and positive directions.",
    publication_date: "2018-02-08",
    www_link: "https://patents.google.com/patent/US20180037625A1/en",
    owner: "Assignee N/A",
    image: "https://api.projectpq.ai/patents/US20180037625A1/thumbnails/1",
    alias: "June et al.",
    inventors: ["Carl H. June", "John Scholler", "Avery D. Posey, Jr."],
    score: 0.1084865927696228,
    snippet: null,
    mapping: null,
    index: "C07K",
  },
  {
    id: "US11594135B2",
    type: "patent",
    publication_id: "US11594135B2",
    title: "Methods of CD40 activation and immune checkpoint blockade",
    abstract:
      "Provided herein are methods and compositions for treating cancer in an individual comprising administering to the individual an effective amount of at least one immune checkpoint inhibitor and a chimeric CD 154 polypeptide. Also provided herein are methods of enhanced immune function.",
    publication_date: "2023-02-28",
    www_link: "https://patents.google.com/patent/US11594135B2/en",
    owner: "MEMGEN, Inc.",
    image: "https://api.projectpq.ai/patents/US11594135B2/thumbnails/1",
    alias: "Overwijk et al.",
    inventors: [
      "Willem W. Overwijk",
      "Manisha Singh",
      "Patrick Hwu",
      "Mark Cantwell",
    ],
    score: 0.10937756299972534,
    snippet: null,
    mapping: null,
    index: "A61K",
  },
];

const OPENAI_API_KEY =
  "sk-proj-74pk8B5\_OtndfUN8p3QrlBSS5Y2mAqHzTbj2zIady\_rwNQQuhnz4Q8mx8mg3l0Xom4MSopvfh5T3BlbkFJD7xuDkUqwT2l91nqESicdyQFgjW2cpSqf9f9e-WBsYesO2tjIpwAhL3zGuLh\_VtQ464jN3QmIA";

async function getOpenAIResponse(prompt: string): Promise<string> {
  
 try { const response = await axios.post(
  "https://api.openai.com/v1/chat/completions",
  
  {
    model: "gpt-4", // Or use 'gpt-3.5-turbo' for a smaller model
    messages: [
      { role: "system", content: "You are a helpful assistant." }, // System message to set the behavior of the assistant
      { role: "user", content: prompt }, // User message with the prompt
    ],
    max_tokens: 5000, // Limit the number of tokens
  },
  {
    headers: {
      Authorization: `Bearer ${OPENAI_API_KEY}`,
    },
  }
);
   return response.data.choices[0].message.content.trim();
  //  return greeting
 } catch (error:any) {
  if(error?.status == 429){
    const errorMessage ="OpenAI credits have been exhausted";
    throw new Error(errorMessage);
  }
  else{
    const errorMessage =
    error?.response?.data?.error?.message || "An unexpected error occurred.";
    throw new Error(errorMessage);
  }
 }
  
  
 
}

async function createHistory(message: string) {
  const user_id = await localStorage.getItem("user_id")

  //   const user_id = user_id;
  //   const url = `${GLOBAL_URL}/api/chats/${user_id}/messages`;
  const url = `${GLOBAL_URL}/api/chats/new`;

  const bodyData = {
    title: message,
    email: "mohit@gmail.com",
  };

  try {
    const response = await fetch(url, {
      method: "POST", // HTTP POST request
      headers: {
        "Content-Type": "application/json", // Ensure content is JSON
      },
      body: JSON.stringify(bodyData), // Convert body data to JSON
    });

    if (!response.ok) {
      // getHistory();
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json(); // Parse the response as JSON
  } catch (error) {
    console.error("Error:", error);
  }
}

const getHistory = async () => {
  const url = `${GLOBAL_URL}/api/chats/`;
  const bodyData = {
    email: "mohit@gmail.com",
  };

  try {
    const response = await fetch(url, {
      method: "POST", // HTTP POST request
      headers: {
        "Content-Type": "application/json", // Ensure content is JSON
      },
      body: JSON.stringify(bodyData), // Convert body data to JSON
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json(); // Parse the response as JSON
  } catch (error) {
    console.error("Error:", error);
  }
};

export interface IUseSendMessage {
  sendMessage: any
  messages: Message[];
  loading: boolean;
  setMessages:any
}
interface Message {
  isResponse: boolean;
  message: string;
  time: Date;
  results?: any[];
}
interface sendMessageInput {
  user_input: string;
  session_id: string;
}

interface Patent {
  id: string;
  type: string;
  publication_id: string;
  title: string;
  abstract: string;
  publication_date: string;
  www_link: string;
  owner: string;
  image: string;
  alias: string;
  inventors: string[];
  score: number;
  snippet: string | null;
  mapping: string | null;
  index: string;
}

interface ApiResponse {
  results: Patent[];
  query: string;
  latent_query: string;
}

interface Data {
  content?: Patent[];
}
const request = "http://34.94.96.59:8080/infindbot/ask";

const RECCOMENDATION_KEY = "product_recommendations";

export const useSendMessage = (onMessageReceived:(decrement?:boolean)=>void): IUseSendMessage => {
  const [loading, setLoading] = useState(false);
  const [sessionId, setSessionId] = useState<string>();
  const [messages, setMessages] = useState<Message[]>([]);
  const [decrement,setDecrement]=useState<boolean>(true);
  const { conversation_id } = useParams();
  const { messagesContext, setMessageContext } = useContextMessages();
 
  useEffect(() => {
    setSessionId(Uuid());
    // getHistory();
  }, []);




  const sendMessage = async (message: string, conversation_id:string) => {
    if (!sessionId) return;
    setLoading(true);
    const input: sendMessageInput = {
      user_input: message,
      session_id: sessionId,
    };
    setMessages((prev) => [
      ...prev,
      { isResponse: false, message, time: new Date(Date.now()) },
    ]);
    
    setMessageContext((prev:any) => [
      ...prev,
      { isResponse: false, message, time: new Date(Date.now()) },
    ]);

    try {
     
      try {
       
        const intermediate = await getOpenAIResponse(
          "Convert this query into something searchable over PQAI" +
            '"' +
            message +
            '"'
        );      
        const response = await fetch(
          "https://api.projectpq.ai/search/102?" +
            "q=" +
            intermediate +
            "&n=10&type=patent&token=542fb33d369eadc162d13b85d7c17be6"
        ); 
        const apiResponse: ApiResponse = await response.json();

        const prompt_openai =
          "Answer the following question " +
          '"' +
          message +
          '"' +
          "with the following context of patents in json format" +
          JSON.stringify(apiResponse.results) +
          "Keep the response format easily readable. Summarize the response in a paragraph within 100 words. Share references to patent numbers as well";
        const greeting = await getOpenAIResponse(prompt_openai);
       
        const data: Data = {};
     
        data.content = apiResponse.results;

        // Access the content safely
        const results = data?.content as Patent[];

        const updateMessages =  [
          ...messages,
          {
           
            isResponse: true,
            message: greeting,
            time: new Date(Date.now()),
            results,
          },
        ]

        setMessages((prev) => [
          ...prev,
          {
           
            isResponse: true,
            message: greeting,
            time: new Date(Date.now()),
            results,
          },
        ]);
        setMessageContext((prev:any) => [
          ...prev,
          {
           
            isResponse: true,
            message: greeting,
            time: new Date(Date.now()),
            results,
          },
        ]);
        onMessageReceived(true);
        // setTimeout(() => {
        //   storeConversation(updateMessages, message, conversation_id)
        // },3000)
        
      } catch (error:any) {
       NotificationManager.error(error.message);
       
       onMessageReceived(false);
        // setMessages((prev) => [
        //   ...prev,
        //   {
          
        //     isResponse: false,
        //     message:  error.message,
        //     time: new Date(Date.now()),
        //    results:[]
        //   },
        // ]);
      }
      // } else {
      //     setMessages(prev => [ ...prev, {isResponse: true, message: data?.content, time: new Date(Date.now())}])
      // }
    } catch (e) {
      console.log(e);
      setMessages((prev) => [
        ...prev,
        {
       
          isResponse: true,
          message: "Infind api failed",
          time: new Date(Date.now()),
        },
      ]);
      setMessageContext((prev:any) => [
        ...prev,
        {
       
          isResponse: true,
          message: "Infind api failed",
          time: new Date(Date.now()),
        },
      ]);
    } finally {
      setLoading(false);
    }
  };

  const storeConversation = async (messages: any, inputText:any, conversation_id:string) => {
    try {
      const data: object = {
        conversation_id: conversation_id,
        chats: messages,
        title: inputText?.length > 10 ? inputText.slice(0, 10) : inputText,
      };
      const response = await axios.post(
        `${GLOBAL_URL}/api/conversation/storeConversation`,
        data
      );
      if (response.status === 200) {
        
      } else {
        console.log("error in crateConversationId");
      }
    } catch (error) {
      return { hasPlan: false, hasCredits: false };
    }
  };

  return { sendMessage, loading: loading && !!sessionId, messages, setMessages};
};

// import axios from "axios";
// import { mockResults } from "./mock";
// import React, { useEffect, useState } from "react";
// import { v4 as Uuid } from "uuid";
// import { GLOBAL_URL } from "../utils/constant";

// const greeting =
//   "The CAR-T therapies targeting CD19 are used in cancer treatment with several patent applications. US20220047636A1 involves a method using chimeric antigen receptors (CARs) that bind to CD79b and CD19 to treat cancer patients. US20150283178A1 and US20210284752A1 both offer methods for treating diseases connected with CD19 expression using anti-CD19 CARs. Another patent, US20190117691A1, involves a PD-1 CAR-T cell for tumor treatment. US11154575B2 presents approaches that involve the use of anti-CD19 CAR expressing immune cells in immunotherapy. Lastly, US20180037625A1 describes the use of the CD2 signaling domain in second-generation CARs.";
// const results = [
//   {
//     id: "US20220047636A1",
//     type: "patent",
//     publication_id: "US20220047636A1",
//     title: "CHIMERIC ANTIGEN RECEPTORS TARGETING CD79B AND CD19",
//     abstract:
//       "Described herein are upfront methods for treating a patient suffering from a cancer. The method includes administering to the patient a therapeutically effective amount of an anti-cancer therapy including a chimeric antigen receptor (CAR) including an extracellular domain including a CD79b-binding domain and a CD19-binding domain, wherein the patient has not been previously treated for the cancer. The invention accordingly also relates to methods of producing and utilizing, e.g., T cells including CARs having an extracellular domain that binds CD79b and CD19.",
//     publication_date: "2022-02-17",
//     www_link: "https://patents.google.com/patent/US20220047636A1/en",
//     owner: "The General Hospital Corporation",
//     image: "https://api.projectpq.ai/patents/US20220047636A1/thumbnails/1",
//     alias: "Maus",
//     inventors: ["Marcela V. Maus"],
//     score: 0.09328633546829224,
//     snippet: null,
//     mapping: null,
//     index: "C07K",
//   },
//   {
//     id: "US20150283178A1",
//     type: "patent",
//     publication_id: "US20150283178A1",
//     title: "TREATMENT OF CANCER USING ANTI-CD19 CHIMERIC ANTIGEN RECEPTOR",
//     abstract:
//       "The invention provides compositions and methods for treating diseases associated with expression of CD19, e.g., by administering a recombinant T cell comprising the CD19 CAR as described herein, in combination with a kinase inhibitor, e.g., a kinase inhibitor described herein. The invention also provides kits and compositions described herein.",
//     publication_date: "2015-10-08",
//     www_link: "https://patents.google.com/patent/US20150283178A1/en",
//     owner: "Assignee N/A",
//     image: "https://api.projectpq.ai/patents/US20150283178A1/thumbnails/1",
//     alias: "June et al.",
//     inventors: [
//       "Carl H. June",
//       "David L. Porter",
//       "Marcela Maus",
//       "Mariusz Wasik",
//       "Saar Gill",
//       "Joseph Fraietta",
//       "Marco Ruella",
//       "John Byrd",
//       "Jason Dubovsky",
//       "Amy Johnson",
//       "Natarajan Muthusamy",
//       "Saad Kenderian",
//       "Joan Mannick",
//       "David Glass",
//       "Leon Murphy",
//       "Jennifer Brogdon",
//       "William Raj Sellers",
//     ],
//     score: 0.09766119718551636,
//     snippet: null,
//     mapping: null,
//     index: "A61K",
//   },
//   {
//     id: "US20190117691A1",
//     type: "patent",
//     publication_id: "US20190117691A1",
//     title:
//       "PD-1 CAR-T CELL, PREPARATION METHOD THEREFOR, AND APPLICATION THEREOF",
//     abstract:
//       "Provided are a PD-1 CAR-T cell, a preparation method thereof, and an application thereof. By means of chimeric antigen receptor-modified T cell transformation, PD-1-CD8™-4-1BB-CD3ζ molecules are expressed in a T cell. The CAR-T cell prepared by the method can specifically recognize and bind with tumor cells with high PDL-1 protein expression, and is applicable to preparation of a drug for preventing and treating tumor diseases.",
//     publication_date: "2019-04-25",
//     www_link: "https://patents.google.com/patent/US20190117691A1/en",
//     owner: "ASCLEPIUS (Suzhou) TECHNOLOGY COMPANY GROUP CO., LTD.",
//     image: "https://api.projectpq.ai/patents/US20190117691A1/thumbnails/1",
//     alias: "LI",
//     inventors: ["HUASHUN LI"],
//     score: 0.09858757257461548,
//     snippet: null,
//     mapping: null,
//     index: "C12N",
//   },
//   {
//     id: "US20210284752A1",
//     type: "patent",
//     publication_id: "US20210284752A1",
//     title:
//       "TREATMENT OF CANCER USING HUMANIZED ANTI-CD19 CHIMERIC ANTIGEN RECEPTOR",
//     abstract:
//       "The invention provides compositions and methods for treating diseases associated with expression of CD19. The invention also relates to chimeric antigen receptor (CAR) specific to CD19, vectors encoding the same, and recombinant T cells comprising the CD19 CAR. The invention also includes methods of administering a genetically modified T cell expressing a CAR that comprises a CD19 binding domain.",
//     publication_date: "2021-09-16",
//     www_link: "https://patents.google.com/patent/US20210284752A1/en",
//     owner: "Novartis AG",
//     image: "https://api.projectpq.ai/patents/US20210284752A1/thumbnails/1",
//     alias: "Brogdon et al.",
//     inventors: [
//       "Jennifer Brogdon",
//       "Carl H. June",
//       "Andreas Loew",
//       "Marcela Maus",
//       "John Scholler",
//     ],
//     score: 0.09944474697113037,
//     snippet: null,
//     mapping: null,
//     index: "C07K",
//   },
//   {
//     id: "US20210128618A1",
//     type: "patent",
//     publication_id: "US20210128618A1",
//     title: "BCMA-TARGETED CAR-T CELL THERAPY OF MULTIPLE MYELOMA",
//     abstract:
//       "Provided herein is a method of treating a subject who has a cancer. At least one dose of chimeric antigen receptor (CAR)-T cells comprising a CAR comprising a polypeptide is administered to the subject. The peptide comprises an extracellular antigen binding domain with at least two BCMA-binding moieties, a transmembrane domain, and an intracellular signaling domain. The dose of CAR-T cells administered to the subject is from 4.0×105 to 1.0×106 of CAR-T cells per kilogram of the subject's mass. Alternatively, the dose comprises 1×106 to 1×108 of the CAR-T cells.",
//     publication_date: "2021-05-06",
//     www_link: "https://patents.google.com/patent/US20210128618A1/en",
//     owner: "Janssen Biotech, Inc.",
//     image: "https://api.projectpq.ai/patents/US20210128618A1/thumbnails/1",
//     alias: "UBANI",
//     inventors: ["Enrique ZUDAIRE UBANI"],
//     score: 0.10206788778305054,
//     snippet: null,
//     mapping: null,
//     index: "A61K",
//   },
//   {
//     id: "US20150118202A1",
//     type: "patent",
//     publication_id: "US20150118202A1",
//     title: "Methods for Treatment of Cancer",
//     abstract:
//       "The present invention provides compositions and methods for treating cancer in a human. The invention includes relates to administering a genetically modified T cell to express a CAR wherein the CAR comprises an antigen binding domain, a transmembrane domain, a costimulatory signaling region, and a CD3 zeta signaling domain",
//     publication_date: "2015-04-30",
//     www_link: "https://patents.google.com/patent/US20150118202A1/en",
//     owner: "Assignee N/A",
//     image: "https://api.projectpq.ai/patents/US20150118202A1/thumbnails/1",
//     alias: "June et al.",
//     inventors: [
//       "Carl H. June",
//       "Bruce L. Levine",
//       "David L. Porter",
//       "Michael D. Kalos",
//       "Michael C. Milone",
//     ],
//     score: 0.10222834348678589,
//     snippet: null,
//     mapping: null,
//     index: "C07K",
//   },
//   {
//     id: "US20180258391A1",
//     type: "patent",
//     publication_id: "US20180258391A1",
//     title: "Compositions and Methods for Treatment of Cancer",
//     abstract:
//       "The present invention provides compositions and methods for treating cancer in a human. The invention includes relates to administering a genetically modified T cell to express a CAR wherein the CAR comprises an antigen binding domain, a transmembrane domain, a costimulatory signaling region, and a CD3 zeta signaling domain.",
//     publication_date: "2018-09-13",
//     www_link: "https://patents.google.com/patent/US20180258391A1/en",
//     owner: "Assignee N/A",
//     image: "https://api.projectpq.ai/patents/US20180258391A1/thumbnails/1",
//     alias: "June et al.",
//     inventors: [
//       "Carl H. June",
//       "Bruce L. Levine",
//       "David L. Porter",
//       "Michael D. Kalos",
//       "Michael C. Milone",
//     ],
//     score: 0.10275077819824219,
//     snippet: null,
//     mapping: null,
//     index: "C07K",
//   },
//   {
//     id: "US11154575B2",
//     type: "patent",
//     publication_id: "US11154575B2",
//     title:
//       "Cancer immunotherapy using CD19-directed chimeric antigen receptors",
//     abstract:
//       "Several embodiments provided for relate to methods of using of anti-CD19 CAR expressing immune cells in immunotherapy. Provided for herein in several embodiments are immune cell-based (e.g., natural killer (NK) cell) compositions comprising CD19-directed chimeric antigen receptors. In some, embodiments the anti-CD19 binder portion of the CAR is humanized. In several embodiments, the humanized anti-CD19 CAR expressing cells exhibit enhanced expression of the CAR as well as enhanced cytotoxicity and/or persistence.",
//     publication_date: "2021-10-26",
//     www_link: "https://patents.google.com/patent/US11154575B2/en",
//     owner: "Nkarta, Inc.",
//     image: "https://api.projectpq.ai/patents/US11154575B2/thumbnails/1",
//     alias: "Trager et al.",
//     inventors: [
//       "James Barnaby Trager",
//       "Luxuan Guo Buren",
//       "Chao Guo",
//       "Mira Tohmé",
//       "Ivan Chan",
//       "Alexandra Leida Liana Lazetic",
//     ],
//     score: 0.1040908694267273,
//     snippet: null,
//     mapping: null,
//     index: "C07K",
//   },
//   {
//     id: "US20180037625A1",
//     type: "patent",
//     publication_id: "US20180037625A1",
//     title:
//       "USE OF THE CD2 SIGNALING DOMAIN IN SECOND-GENERATION CHIMERIC ANTIGEN RECEPTORS",
//     abstract:
//       "The present invention provides compositions and methods for treating cancer in a human. The invention includes relates to administering a genetically modified T cell expressing a CAR having an antigen binding domain, a transmembrane domain, a CD2 signaling domain, and a CD3 zeta signaling domain. The invention also includes incorporating CD2 into the CAR to alter the cytokine production of CAR-T cells in both negative and positive directions.",
//     publication_date: "2018-02-08",
//     www_link: "https://patents.google.com/patent/US20180037625A1/en",
//     owner: "Assignee N/A",
//     image: "https://api.projectpq.ai/patents/US20180037625A1/thumbnails/1",
//     alias: "June et al.",
//     inventors: ["Carl H. June", "John Scholler", "Avery D. Posey, Jr."],
//     score: 0.1084865927696228,
//     snippet: null,
//     mapping: null,
//     index: "C07K",
//   },
//   {
//     id: "US11594135B2",
//     type: "patent",
//     publication_id: "US11594135B2",
//     title: "Methods of CD40 activation and immune checkpoint blockade",
//     abstract:
//       "Provided herein are methods and compositions for treating cancer in an individual comprising administering to the individual an effective amount of at least one immune checkpoint inhibitor and a chimeric CD 154 polypeptide. Also provided herein are methods of enhanced immune function.",
//     publication_date: "2023-02-28",
//     www_link: "https://patents.google.com/patent/US11594135B2/en",
//     owner: "MEMGEN, Inc.",
//     image: "https://api.projectpq.ai/patents/US11594135B2/thumbnails/1",
//     alias: "Overwijk et al.",
//     inventors: [
//       "Willem W. Overwijk",
//       "Manisha Singh",
//       "Patrick Hwu",
//       "Mark Cantwell",
//     ],
//     score: 0.10937756299972534,
//     snippet: null,
//     mapping: null,
//     index: "A61K",
//   },
// ];

// const OPENAI_API_KEY =
//   "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";

// async function getOpenAIResponse(prompt: string): Promise<string> {
//   const response = await axios.post(
//     "https://api.openai.com/v1/chat/completions",
//     {
//       model: "gpt-4", // Or use 'gpt-3.5-turbo' for a smaller model
//       messages: [
//         { role: "system", content: "You are a helpful assistant." }, // System message to set the behavior of the assistant
//         { role: "user", content: prompt }, // User message with the prompt
//       ],
//       max_tokens: 5000, // Limit the number of tokens
//     },
//     {
//       headers: {
//         Authorization: `Bearer ${OPENAI_API_KEY}`,
//       },
//     }
//   );

//   return response.data.choices[0].message.content.trim();
// }

// async function createHistory(message: string) {
//   //   const user_id = "66e290c88c422e853c84981e";
//   //   const url = `${GLOBAL_URL}/api/chats/${user_id}/messages`;
//   const url = `${GLOBAL_URL}api/chats/new`;

//   const bodyData = {
//     title: message,
//     email: "mailto:mohit@gmail.com",
//   };

//   try {
//     const response = await fetch(url, {
//       method: "POST", // HTTP POST request
//       headers: {
//         "Content-Type": "application/json", // Ensure content is JSON
//       },
//       body: JSON.stringify(bodyData), // Convert body data to JSON
//     });
//     console.log("🚀  createHistory  response:", response);

//     if (!response.ok) {
//       getHistory();
//       throw new Error(`HTTP error! status: ${response.status}`);
//     }

//     const data = await response.json(); // Parse the response as JSON
//     console.log("Success:", data);
//   } catch (error) {
//     console.error("Error:", error);
//   }
// }

// const getHistory = async () => {
//   const url = `${GLOBAL_URL}/api/chats/`;
//   const bodyData = {
//     email: "mailto:mohit@gmail.com",
//   };

//   try {
//     const response = await fetch(url, {
//       method: "POST", // HTTP POST request
//       headers: {
//         "Content-Type": "application/json", // Ensure content is JSON
//       },
//       body: JSON.stringify(bodyData), // Convert body data to JSON
//     });
//     console.log("🚀  getHistory  response:", response);

//     if (!response.ok) {
//       throw new Error(`HTTP error! status: ${response.status}`);
//     }

//     const data = await response.json(); // Parse the response as JSON
//     console.log("Success:", data);
//   } catch (error) {
//     console.error("Error:", error);
//   }
// };

// export interface IUseSendMessage {
//   sendMessage: (message: string) => void;
//   messages: Message[];
//   loading: boolean;
// }
// interface Message {
//   isResponse: boolean;
//   message: string;
//   time: Date;
//   results?: any[];
// }
// interface sendMessageInput {
//   user_input: string;
//   session_id: string;
// }

// interface Patent {
//   id: string;
//   type: string;
//   publication_id: string;
//   title: string;
//   abstract: string;
//   publication_date: string;
//   www_link: string;
//   owner: string;
//   image: string;
//   alias: string;
//   inventors: string[];
//   score: number;
//   snippet: string | null;
//   mapping: string | null;
//   index: string;
// }

// interface ApiResponse {
//   results: Patent[];
//   query: string;
//   latent_query: string;
// }

// interface Data {
//   content?: Patent[];
// }
// const request = "http://34.94.96.59:8080/infindbot/ask";

// const RECCOMENDATION_KEY = "product_recommendations";

// export const useSendMessage = (): IUseSendMessage => {
//   const [loading, setLoading] = useState(false);
//   const [sessionId, setSessionId] = useState<string>();
//   const [messages, setMessages] = useState<Message[]>([]);
//   useEffect(() => {
//     setSessionId(Uuid());
//     getHistory();
//   }, []);

//   const sendMessage = async (message: string) => {
//     if (!sessionId) return;
//     setLoading(true);
//     const input: sendMessageInput = {
//       user_input: message,
//       session_id: sessionId,
//     };
//     setMessages((prev) => [
//       ...prev,
//       { isResponse: false, message, time: new Date(Date.now()) },
//     ]);
//     try {
//       // const response = await fetch(request, {
//       //     method: 'POST',
//       //     headers: {
//       //         'Content-Type': 'application/json'
//       //     },
//       //     body: JSON.stringify(input)
//       // });
//       // const data = await response.json()
//       // console.log('data: ', data);
//       // console.log('data?.name === RECCOMENDATION_KEY: ', data?.name === RECCOMENDATION_KEY);
//       // if(data?.name === RECCOMENDATION_KEY) {
//       try {
//         // Temp cause api is not returning results in correct format
//         console.log(message);
//         const intermediate = await getOpenAIResponse(
//           "Convert this query into something searchable over PQAI" +
//             '"' +
//             message +
//             '"'
//         );
//         // await createHistory(message);

//         const response = await fetch(
//           "https://api.projectpq.ai/search/102?" +
//             "q=" +
//             intermediate +
//             "&n=10&type=patent&token=542fb33d369eadc162d13b85d7c17be6"
//         ); // Replace with the actual API endpoint
//         const apiResponse: ApiResponse = await response.json();

//         const prompt_openai =
//           "Answer the following question " +
//           '"' +
//           message +
//           '"' +
//           "with the following context of patents in json format" +
//           JSON.stringify(apiResponse.results) +
//           "Keep the response format easily readable. Summarize the response in a paragraph within 100 words. Share references to patent numbers as well";
//         const greeting = await getOpenAIResponse(prompt_openai);
//         getOpenAIResponse('Tell me a joke').then(console.log);

//         const data: Data = {};
//         data.content = apiResponse.results;

//         // Access the content safely
//         const results = data?.content as Patent[];

//         console.log(results);
//         console.log("dgsdgdsgsd");

//         setMessages((prev) => [
//           ...prev,
//           {
//             isResponse: true,
//             message: greeting,
//             time: new Date(Date.now()),
//             results,
//           },
//         ]);
//       } catch (e) {
//         setMessages((prev) => [
//           ...prev,
//           {
//             isResponse: true,
//             message: "Here are some recommendations based on your input",
//             time: new Date(Date.now()),
//             results: [
//               mockResults,
//               mockResults,
//               mockResults,
//               mockResults,
//               mockResults,
//               mockResults,
//             ],
//           },
//         ]);
//       }
//       // } else {
//       //     setMessages(prev => [ ...prev, {isResponse: true, message: data?.content, time: new Date(Date.now())}])
//       // }
//     } catch (e) {
//       console.log(e);
//       setMessages((prev) => [
//         ...prev,
//         {
//           isResponse: true,
//           message: "Infind api failed",
//           time: new Date(Date.now()),
//         },
//       ]);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return { sendMessage, loading: loading && !!sessionId, messages };
// };